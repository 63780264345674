.domain {
  background-color: #F7F8FA;
  border: 1px solid #F7F8FA;
  border-radius: var(--radius-050);
  max-width: 1000px;
  padding-bottom: 16px;
  page-break-inside: avoid;
  width: 100%;
}

.sortable-ghost {
  opacity: .3;
}
