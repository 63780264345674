.side-panel {
  background-color: var(--color-side-panel-bg);
  box-shadow: -2px 0px 8px 0px rgba(#24292F, 0.1);
  height: 100%;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: 100;
}

.side-panel-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  margin: 40px;
}

.scrim {
  background-color: #000;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
