.material-icons {
  &.mirror {
    transform: scaleX(-1);
  }

  &.rotate90 {
    transform: rotate(90deg);
  }

  &.mirror90 {
    transform: rotate(90deg) scaleX(-1);
  }
}
