.mdc-dialog {
  @include dialog.scrim-color(#000, 0.6);
  @include dialog.container-fill-color(var(--color-bg-default));
  z-index: 1001;

  .mdc-dialog__surface {
    @media (min-width: 768px) {
      width: 512px;
    }

    @media (min-width: 1012px) {
      width: 640px;
    }

    max-width: calc(100vw - 48px - 48px);
    width: 400px;
  }

  .mdc-dialog__title,
  .mdc-dialog__content,
  .mdc-dialog__actions {
    padding: 16px;
    margin: 0;
  }

  .mdc-dialog__title {
    background-color: var(--color-canvas-subtle);
    border-bottom: 1px solid var(--color-border-default);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: inherit;

    &:before {
      height: 0;
    }
  }

  .mdc-dialog__content {
    max-height: calc(90vh - 64px - 64px);
  }

  .mdc-dialog__actions {
    border-top: 1px solid var(--color-border-default);
  }
}

.mdc-dialog--overflow-visible {
  .mdc-dialog__surface,
  .mdc-dialog__content {
    overflow: visible;
  }
}

.mdc-dialog__button {
  text-align: center;
}

.mdc-dialog-item {
  border-top: 1px solid $gainsboro;
  color: $text-color;

  &:hover {
    background-color: $gainsboro;
  }
}

// Woots
.woots-video-dialog {
  .mdc-dialog__container {
    width: 100%;

    .mdc-dialog__surface {
      max-width: none;
      width: 55%;
    }
  }

  .video-frame {
    height: calc(75vh - 6px);
    width: 100%;
  }
}

.high-contrast {
  .mdc-dialog {
    .mdc-dialog__content,
    .mdc-dialog__title,
    [data-js-draggable-header] {
      background-color: $high-contrast-background-color !important;
      color: $high-contrast-color;
    }

    .mdc-dialog__title {
      border-bottom: 1px solid $high-contrast-color;
    }

    .mdc-dialog__actions {
      border-top: 1px solid $high-contrast-color;
    }
  }
}
