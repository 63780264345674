.label {
  background-color: #FFF;
  border-radius: 12px;
  border: 1px solid #E0E0E2;
  box-sizing: border-box;
  color: var(--color-text-900);
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  line-height: 18px;
  padding: 0px 8px;
  vertical-align: text-bottom;
  white-space: nowrap;

  .material-icons {
    font-size: 16px;
    line-height: 16px;
  }

  &.label--live {
    border-color: $green-crayola;
    color: $green-crayola;
  }

  &.label--outline {
    border-color: #e1e4e8;
    color: #586069;
  }

  &.label--contained {
    background-color: $cultured;
    border: 0;
  }


  &.label--round {
    border-radius: 2em;
  }

  &--large {
    font-size: 14px;
    padding: 4px 8px;
  }

  // Colors
  &.label--amber {
    border-color: $mikado-yellow;
  }

  &.label--blue {
    border-color: $blue;
  }

  &.label--cyan {
    border-color: $cyan;
  }

  &.label--deep-orange {
    border-color: $dark-orange;
  }

  &.label--deep-purple {
    border-color: $navy-blue;
  }

  &.label--green {
    border-color: $mountain-meadow;
  }

  &.label--indigo {
    border-color: $indigo;
  }

  &.label--light-blue {
    border-color: $light-blue;
  }

  &.label--light-green {
    border-color: $green;
  }

  &.label--lime {
    border-color: $lime
  }

  &.label--orange {
    border-color: $orange;
  }

  &.label--pink {
    border-color: $deep-pink;
  }

  &.label--purple {
    border-color: $han-purple;
  }

  &.label--red {
    border-color: $cardinal;
  }

  &.label--teal {
    border-color: $mountain-meadow;
  }

  &.label--yellow {
    border-color: $mikado-yellow;
  }
}

.woots {
  .label {
    border-radius: 0;
  }
}

.status-label {
  @extend .px-2;
  @extend .py-1;
  @extend .text-bold;
  @extend .rounded-1;

  &--default {
      @extend .text-support;
      @extend .f5;
      background-color: var(--color-bg-100);
  }

  &--success {
      background-color: var(--color-success-050);
      color: var(--color-text-success-text);
  }

  &--danger {
      background-color: var(--color-danger-050);
      color: var(--color-text-danger-text);
  }
}
