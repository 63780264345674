.progress-bar {
  background-color: var(--color-progress-bar-unstarted-bg);
  border-radius: var(--radius-050);
  display: inline-block;
  height: 8px;
  overflow: hidden;
  width: 160px;
  position: relative;


  .progress,
  .finished-progress {
    position: absolute;
    background-color: var(--color-progress-bar-finished-bg);
    display: block;
    height: 100%;
  }

  .started-progress {
    @extend .finished-progress;
    background-color: var(--color-progress-bar-started-bg);
  }
}

.progress-legend {
  .progress-label {
    border-radius: var(--radius-050);
    display: inline-block;
    height: 8px;
    width: 16px;
  }

  .unstarted-label {
    @extend .progress-label;
    background-color: var(--color-progress-bar-unstarted-bg);
  }

  .started-label {
    @extend .progress-label;
    background-color: var(--color-progress-bar-started-bg);
  }

  .finished-label {
    @extend .progress-label;
    background-color: var(--color-progress-bar-finished-bg);
  }
}
