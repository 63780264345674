.circle {
  align-items: center;
  background-color: var(--color-canvas-default);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  min-height: 48px;
  min-width: 48px;
}

.text-separator-circle {
  @extend .circle;
  background-color: var(--color-text-support-text);
  display: inline-flex;
  min-height: 4px;
  min-width: 4px;
}

.default-separator-circle {
  @extend .circle;
  background-color: var(--color-button-tertiary-text);
  min-height: 8px;
  min-width: 8px;
}
