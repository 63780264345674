:root {
  --color-primary-050: #FFF4ED;
  --color-primary-100: #FFE5D5;
  --color-primary-200: #FFBF9D;
  --color-primary-300: #FF9F72;
  --color-primary-400: #FD6D3A;
  --color-primary-500: #FC4713;
  --color-primary-600: #ED2C09;
  --color-primary-700: #C41D0A;
  --color-primary-800: #9C1910;
  --color-primary-900: #7D1811;
  --color-primary-950: #440806;

  --color-secondary-050: #EBEEFF;
  --color-secondary-100: #DBDFFF;
  --color-secondary-200: #BEC4FF;
  --color-secondary-300: #979DFF;
  --color-secondary-400: #746EFF;
  --color-secondary-500: #604CFF;
  --color-secondary-600: #5830FF;
  --color-secondary-700: #4A20E2;
  --color-secondary-800: #3B1DB6;
  --color-secondary-900: #33208F;
  --color-secondary-950: #201353;

  --color-text-050: #F4F6F7;
  --color-text-100: #E3E8EA;
  --color-text-200: #C9D2D8;
  --color-text-300: #A4B3BC;
  --color-text-400: #778C99;
  --color-text-500: #5C707E;
  --color-text-600: #4F5F6B;
  --color-text-700: #444F5A;
  --color-text-800: #3D454D;
  --color-text-900: #363C43;
  --color-text-950: #24292F;

  --color-bg-050: #FFFFFF;
  --color-bg-100: #F7F7F7;
  --color-bg-150: #F2F2F2;
  --color-bg-200: #E8E8E8;
  --color-bg-300: #BDBDBD;
  --color-bg-400: #989898;
  --color-bg-500: #7C7C7C;
  --color-bg-600: #656565;
  --color-bg-700: #525252;
  --color-bg-800: #464646;
  --color-bg-850: #404040;
  --color-bg-900: #3D3D3D;
  --color-bg-950: #292929;

  --color-danger-050: #FEF2F3;
  --color-danger-100: #FEE2E4;
  --color-danger-200: #FECACE;
  --color-danger-300: #FCA5AC;
  --color-danger-400: #F8717C;
  --color-danger-500: #EF4452;
  --color-danger-600: #CB212F;
  --color-danger-700: #B91C29;
  --color-danger-800: #991B25;
  --color-danger-900: #7F1D25;
  --color-danger-950: #450A0F;

  --color-warning-050: #FFFEEA;
  --color-warning-100: #FFFCC5;
  --color-warning-200: #FFF986;
  --color-warning-300: #FFEF47;
  --color-warning-400: #FFE11D;
  --color-warning-500: #FDC40A;
  --color-warning-600: #E09700;
  --color-warning-700: #BA6B03;
  --color-warning-800: #96520A;
  --color-warning-900: #7C440B;
  --color-warning-950: #472301;

  --color-info-050: #EDFAFF;
  --color-info-100: #D7F1FF;
  --color-info-200: #B9E8FF;
  --color-info-300: #88DBFF;
  --color-info-400: #50C6FF;
  --color-info-500: #28A7FF;
  --color-info-600: #118AFF;
  --color-info-700: #0969DA;
  --color-info-800: #0F5BBE;
  --color-info-900: #134F95;
  --color-info-950: #11305A;

  --color-success-050: #ECFDF5;
  --color-success-100: #D2F9E4;
  --color-success-200: #A8F2CE;
  --color-success-300: #70E5B5;
  --color-success-400: #36D195;
  --color-success-500: #12B77D;
  --color-success-600: #08A873;
  --color-success-700: #067654;
  --color-success-800: #075E43;
  --color-success-900: #074D3A;
  --color-success-950: #032B21;

  &.woots {
    --color-primary-050: #f2fbf8;
    --color-primary-100: #d4f3ea;
    --color-primary-200: #a8e7d4;
    --color-primary-300: #61cdb0;
    --color-primary-400: #48b99e;
    --color-primary-500: #2f9d85;
    --color-primary-600: #237e6c;
    --color-primary-700: #206558;
    --color-primary-800: #1e5148;
    --color-primary-900: #1d443e;
    --color-primary-950: #0b2824;

    --color-secondary-50: #eff6ff;
    --color-secondary-100: #daeaff;
    --color-secondary-200: #bedbff;
    --color-secondary-300: #91c4ff;
    --color-secondary-400: #5da4fd;
    --color-secondary-500: #3880f9;
    --color-secondary-600: #2664ef;
    --color-secondary-700: #1a4bdb;
    --color-secondary-800: #1b3eb2;
    --color-secondary-900: #1c388c;
    --color-secondary-950: #162455;
  }
}
