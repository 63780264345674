.breadcrumb-item {
  display: inline-block;
  list-style: none;
  white-space: nowrap;

  &--final {
    font-weight: 600;
  }

  &--truncate {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.breadcrumb-item:first-child {
  margin-left: 0;
}

.breadcrumb-item:not(:last-child):after {
  border-right: 0.1em solid var(--color-fg-muted);
  content: "";
  display: inline-block;
  height: 0.8em;
  margin: 0 0.5em;
  transform: rotate(15deg);
}

.breadcrumb-item:last-child a {
  color: var(--color-fg-default);
}

.breadcrumb {
  align-items: center;
  display: flex;
  font-family: var(--font-family-text);
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &--arrow {
    color: black;
    font-size: 18px;
  }
}
