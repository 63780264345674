.subelement {
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: var(--radius-050);
  box-shadow: 0 1px 1px rgba(27,31,35,.1);
  page-break-inside: avoid;
}

.woots {
  .subelement {
    border: 0;
    border-radius: 0;
  }
}
