// Woots
@import '@fontsource/montserrat/400.css';
@import '@fontsource/montserrat/500.css';
@import '@fontsource/montserrat/600.css';
@import '@fontsource/montserrat/700.css';

@import '@fontsource/jost/400.css';
@import '@fontsource/jost/500.css';
@import '@fontsource/jost/600.css';
@import '@fontsource/jost/700.css';

// Ans
@import '@fontsource/nunito/400.css';
@import '@fontsource/nunito/500.css';
@import '@fontsource/nunito/600.css';
@import '@fontsource/nunito/700.css';

@import '@fontsource/poppins/400.css';
@import '@fontsource/poppins/500.css';
@import '@fontsource/poppins/600.css';
@import '@fontsource/poppins/700.css';

:root {
  --mdc-typography-body2-letter-spacing: normal;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-fg-default);
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-header);
  margin-bottom: 0;
  margin-top: 0;
}

@each $key, $font-size, $line-height in (1 32px 40px, 2 24px 32px, 3 20px 28px, 4 16px 24px, 5 14px 20px, 6 12px 20px) {
  .f#{$key} {
    font-size: $font-size !important;
    line-height: $line-height !important;
  }
  h#{$key} {
    font-size: $font-size;
    line-height: $line-height;
  }
}

.text-normal {
  font-weight: 400 !important;
}

.text-medium {
  font-weight: 500 !important;
}

.text-semi-bold {
  font-weight: 600 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-italic {
  font-style: italic !important;
}

.text-link {
  color: var(--color-accent-fg);
}

.text-strikethrough {
  text-decoration-line: line-through !important;
  text-decoration-thickness: 2px !important;
  -webkit-text-decoration-line: line-through !important;
}

.text-left {
  text-align: left !important;
}

.monospace {
  font-family: monospace;
}

a {
  color: var(--color-accent-fg);
  text-decoration: none;
}

p {
  margin-bottom: 10px;
  margin-top: 0;
}

ol, ul {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

blockquote {
  border-inline-start: 4px solid var(--color-bg-200 );
  color: var(--color-text-900);
  font-style: normal;
  padding-left: 16px;
}

.list-style-none {
  list-style: none !important;
}

b, strong {
  font-weight: 700;
}

.lh-condensed-ultra {
  line-height: 1 !important;
}

.lh-condensed {
  line-height: 1.25 !important;
}

.lh-default {
  line-height: 1.5 !important;
}

.lh-0 {
  line-height: 0 !important;
}

.material-icons {
  font-family: 'Material Icons Outlined';
  text-transform: none !important;
  vertical-align: text-bottom;
}

.material-icons-filled {
  color: var(--color-icon-default);
  font-family: 'Material Icons' !important;
  text-transform: none !important;
  vertical-align: text-bottom;
}

.text-capitalized {
  text-transform: capitalize;
}

.mdc-tab {
  text-transform: capitalize;
}

.capitalize {
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.font-heading {
  font-family: 'Poppins', sans-serif;
}

.woots {
  h1, h2, h3, h4, h5, h6, .font-heading {
    font-family: 'Jost', sans-serif;
  }
}

.text-opacity-1 {
  opacity: .87;
}

.text-opacity-2 {
  opacity: .6;
}

.text-opacity-3 {
  opacity: .38;
}

.text-center {
  text-align: center !important;
}

// Woots
.woots-h1,
.woots-h4,
.woots-h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  line-height: 1;
}

.woots-h1 {
  font-size: 64px;
}

.woots-h4 {
  font-size: 40px;
}

.woots-h5 {
  font-size: 32px;
}

.underline--hover:hover {
  text-decoration: underline;
}

.highlight--hover {
  border-radius: var(--radius-100);
  padding-inline: 4px;
  padding-block: 2px;

  &:hover {
    background-color: var(--color-bg-150);
  }

  &:active {
    background-color: var(--color-bg-200);
  }
}

.text-border-bottom {
  border-bottom: 2px solid $black;

  &.border-white {
    border-color: $white;
  }
}

@media only screen and (max-width: 1200px) {
  .woots-h1 {
    font-size: 40px;
  }

  .woots-h4 {
    font-size: 24px;
  }
}

.high-contrast {
  h1, h2, h3, h4, h5, h6 {
    color: $high-contrast-color;
  }
}
