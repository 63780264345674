.checkbox {
  display: inline-block;
  align-content: center;

  width: 18px;
  height: 18px;
  margin: 3px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox__input {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
}

.checkbox__background {
  @extend .d-flex;
  @extend .flex-items-center;
  @extend .flex-justify-center;

  cursor: pointer;

  height: 18px;
  width: 18px;

  background-color: var(--color-canvas-default);

  box-sizing: border-box;
  border-radius: var(--radius-050);
  border: solid 2px var(--color-checkbox-border);

  outline: none;
}

.checkbox__input:checked ~ .checkbox__background,
.checkbox__input:indeterminate ~ .checkbox__background {
  background-color: var(--color-checkbox-bg);
  border: none;
}

.checkbox__input:checked:not(:indeterminate) ~ .checkbox__background .checkbox__indeterminate,
.checkbox__input:indeterminate ~ .checkbox__background .checkbox__checkmark {
  display: none;
}

.checkbox__input ~ .checkbox__background .checkbox__checkmark {
  fill: var(--color-checkbox-mark);
  pointer-events: none;
  transform: scaleX(0) rotate(-45deg);
  transition: 125ms ease-in;
  width: 14px;
  height: 14px;
  opacity: 0;
}

.checkbox__input ~ .checkbox__background .checkbox__indeterminate {
  fill: var(--color-checkbox-mark);
  pointer-events: none;
  transform: scaleX(0) rotate(-45deg);
  transition: 125ms ease-in;
  width: 12px;
  height: 2px;
  opacity: 0;
}

.checkbox__input:checked:not(:indeterminate) ~ .checkbox__background .checkbox__checkmark,
.checkbox__input:indeterminate ~ .checkbox__background .checkbox__indeterminate {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.checkbox__input:disabled ~ .checkbox__background {
  cursor: default;
  opacity: 50%;
}
