.page-header {
  background-color: var(--color-page-header-bg);
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 8px;
  position: relative;

  .page-header--chevron {
    position: absolute;
    right: 40px;
    top: 8px;
    z-index: 2;
  }
}
