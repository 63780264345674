body {
  margin: 0;
  pointer-events: none;
}

input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

.mdc-top-app-bar {
  position: initial;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.white-space-wrap {
  white-space: wrap;
}

.white-space-nowrap {
  white-space: nowrap;
}

.invisible {
  pointer-events: none;
  opacity: 0;
}

[hidden],
.hidden,
.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.sticky {
  position: sticky !important;
  top: 0;

  &--opaque {
    background-color: var(--color-canvas-default);
  }
}

.z-6 {
  z-index: 6 !important;
}

.z-100 {
  z-index: 100 !important;
}

.blur {
  backdrop-filter: blur(2px);
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clear-both {
  clear: both !important;
}

.responsive {
  height: auto !important;
  max-width: 100% !important;
}

.capitalize {
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.left-align {
  text-align: left !important;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

.middle-align {
  align-self: center !important;
}

.center-vertical {
  vertical-align: middle !important;
}

.position-relative {
  position: relative !important;
}

.position-static {
  position: static !important;
}

.position-absolute {
  position: absolute !important;
}

.grid-gap-0 {
  grid-gap: 0 !important;
}

.grid-gap-1 {
  grid-gap: 4px !important;
}

.grid-gap-2 {
  grid-gap: 8px !important;
}

.grid-gap-3 {
  grid-gap: 16px !important;
}

.grid-gap-4 {
  grid-gap: 24px !important;
}

.grid-gap-6 {
  grid-gap: 40px !important;
}

.disabled {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.proctorio-image {
  border-radius: 0 0 200px 0;
  max-width: 200px;
  padding: 100px 100px 100px 100px;
  width: 25vw;
}

.height-full {
  height: 100% !important;
}

.width20 {
  width: 20%;
}

.width-full {
  width: 100% !important;
}

.full-content-width {
  width: max-content !important;
  max-width: max-content !important;
}

rect {
  shape-rendering: crispedges;
}

@media (min-width: 600px) {
  .show-on-phone {
    display: none !important;
  }
}

@media (max-width: 599px) {
  .hide-on-phone {
    display: none !important;
  }
}

@media (max-width: 839px) {
  .hide-on-tablet {
    display: none !important;
  }
}

@media (min-width: 840px) {
  .hide-on-desktop {
    display: none !important;
  }
}

@for $i from 1 through 6 {
  .line-clamp-#{$i} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $i;
  }
}
