.image-map-wrapper {
  border-top: 1px solid #EEE;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .image-mapper {
    max-width: 100%;
    position: relative;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .image-mapper-img {
    max-height: 100%;
    max-width: 100%;
  }

  .image-mapper-svg {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .done-editing {
    margin: 0 auto;
  }
}
