.icon {
  @extend .material-icons;
  color: var(--color-icon-default);
  font-size: 22px;

  &--secondary {
    color: var(--color-secondary-400);
  }

  &--filled {
    font-family: Material Icons !important;
  }
}

.icon-success {
  color: var(--color-icon-success);
}

.filled-success-icon {
  align-self: center;
  background-color: var(--color-icon-filled-success-bg);
  border-radius: var(--radius-100); 
  color: var(--color-icon-filled-success) !important;
}
