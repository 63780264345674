.dz-drag-hover {
  border: 1px dashed black;
  background-color: rgba(0, 0, 0, .3);
}

.suggestion {
  button {
    &:hover {
      background-color: var(--color-secondary-050);
    }
  }
}
