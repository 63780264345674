@each $key, $value in $units {
  .r-#{$key} {
    right: $value !important;
  }

  .l-#{$key} {
    left: $value !important;
  }

  .t-#{$key} {
    top: $value !important;
  }

  .b-#{$key} {
    bottom: $value !important;
  }
}
