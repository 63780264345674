.code-language {
  @include select.filled-density(-2);
  @include select.filled-height(36px);
  @include select.bottom-line-color((default: transparent !important, hover: transparent !important, focus: transparent !important));
  @include select.container-fill-color(transparent !important);
  @include select.ink-color($white !important);
  @include select.dropdown-icon-color((default: $white !important, hover: $white !important, focus: $white !important));
  @include select.variable-width(auto !important);
}

.code-wrapper {
  background-color: #2F3129;
}

.code-console {
  min-height: 18px;
}
