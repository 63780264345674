.rsbtn .rsbtn_toollist .rs-contextmenu {
  left: unset !important;
  right: 100% !important;
}

.rsbtn_toolpanel {
  min-width: auto !important;

  .rsbtn_tools {
    min-width: 209px !important;
    right: 0 !important;
  }
}
