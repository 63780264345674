.survey {
  border: 1px solid var(--color-border-default);
  border-radius: var(--radius-100);
  padding: 24px;
}

.scale {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  label {
    align-items: center;
    border: 1px solid var(--color-border-default);
    border-radius: var(--radius-050);
    color: #464646;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    margin: 4px 3px;
    width: 40px;

    &:hover {
      background-color: #F5F5F5;
      color: #464646;
    }

    &:focus {
      background-color: #E6DEFF;
      color: #2E2E2E;
    }
  }

  input {
    &[type='radio'] {
      background-color: #FFF;
      display: none;

      &:checked + label {
        background-color: #5118FD;
        border-color: #5118FD;
        color: #FFF;
        font-weight: 700;
      }
    }
  }
}
