.dropdown {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

.mdc-data-table {
  .dropdown {
    position: initial;
  }
}

.dropdown-button {
  @extend .f4;

  align-items: center;
  border-radius: var(--radius-100);
  box-sizing: border-box;
  color: var(--color-text-950);
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  height: 40px;
  min-width: 40px;
  justify-content: center;
  padding: 8px;

  &:not(:disabled):hover {
    background-color: var(--color-bg-200);
  }
}

.dropdown-menu {
  @extend .d-flex;
  @extend .flex-column;
  @extend .elevation-1;

  background-color: var(--color-bg-050);
  border-radius: var(--radius-100);
  border: 1px solid var(--color-border-default);
  gap: 16px;
  margin-top: 4px;
  padding: 16px 0;
  position: absolute;
  width: max-content;
  z-index: 10;
}

.dropdown-list {
  @extend .d-flex;
  @extend .flex-column;

  gap: 4px;
  list-style: none;
  max-height: 250px;
  padding: 0 16px;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.dropdown-item {
  @extend .f4;
  @extend .d-flex;
  @extend .flex-items-center;
  @extend .gap-2;

  border-radius: var(--radius-100);
  color: var(--color-text-950);
  cursor: pointer;
  padding: 8px 16px;
  width: -webkit-fill-available;
  max-width: 400px;

  &:not(:disabled):hover {
    background-color: var(--color-bg-100);
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--selected {
    background-color: var(--color-secondary-100) !important;
  }
}
