.chat-messages {
  font-family: Roboto, sans-serif;
  height: 450px;
  line-height: 24px;
  overflow-y: auto;
}

.announcement {
  background-color: #FFF9E4;
  border-left: 4px solid #FFB703;
  padding-left: 14px !important;
  padding-right: 16px !important;
}

.chatbox {
  .chat-messages {
    height: calc(100vh - 418px);
  }
}
