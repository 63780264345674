@media print {
  @page {
    margin: 10mm 0 10mm 0;
    size: auto;
  }

  .hide-on-print {
    display: none !important;
  }

  .avoid-page-break {
    break-inside: avoid;
  }
}
