.badge {
  background-color: var(--color-canvas-subtle);
  border-radius: var(--radius-050);
  color: var(--color-fg-muted);
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  height: 14px;
  line-height: 14px;
  margin: 2px 2px 2px 0;
  padding: 4px;

  .material-icons {
    font-size: 14px;
    line-height: 14px;
  }

  &.badge--blue {
    background-color: #EBF3FF;
    color: #2C79FF;
  }

  &.badge--live {
    background-color: #C4F2D3;
    color: #222;
  }

  &.badge--starting-soon {
    background-color: #F4E7E5;
    color: #222;
  }

  &.badge--warning {
    background-color: #FFF9E4;
    color: #C9A71D;
  }
}

a {
  &.badge,
  .badge {
    &.badge--link {
      background-color: #EFF7FF;
      color: #0366D6;
    }
  }
}
