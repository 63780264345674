.mdc-chip {
  min-width: max-content;

  &.mdc-chip--shaped {
    background-color: $cultured;
    // @include chips.shape-radius(4px, 0);
  }

  &.mdc-chip--tertiary {
    background-color: #595959;
    color: $white;
  }
}

.woots {
  .mdc-chip {
    // @include chips.shape-radius(0,0);
  }
}
