.split-buttons {
  .mdc-icon-button {
    @include icon-button.ink-color(#4A20E2);
  }

  & > * {
    border: solid 1px var(--color-split-button-default-border);
    display: inline-flex;

    &:first-child {
      border-right: none;
      border-radius: var(--radius-100) 0 0 var(--radius-100);

      .mdc-icon-button__ripple {
        &:after,
        &:before {
          border-radius: var(--radius-100) 0 0 var(--radius-100);
        }
      }
    }

    &:last-child {
      border-radius: 0 8px 8px 0;

      .mdc-icon-button__ripple {
        &:after,
        &:before {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
}
