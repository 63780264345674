.summary {
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style: none;

  .summary-item {
    align-items: center;
    display: flex;
    height: 70px;
    text-align: center;
    width: 100%;

    &:first-child {
      border-top-left-radius: var(--radius-100);
      border-bottom-left-radius: var(--radius-100);
    }

    &:not(:last-child) {
      border-right: 1px solid var(--color-border-default);
    }

    &:last-child {
      border-top-right-radius: var(--radius-100);
      border-bottom-right-radius: var(--radius-100);
    }
  }
}
