.filter {
  align-items: center;
  background-color: var(--color-filter-button-bg);
  border: 1px solid var(--color-filter-button-border);
  border-radius: var(--radius-100);
  color: var(--color-filter-button-text);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family-text);
  height: 40px;
  letter-spacing: .25px;
  line-height: 24px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-filter-button-hover-bg);
  }

  &:active {
    border-color: var(--color-filter-button-active-border);
  }

  &--selected {
    background-color: var(--color-filter-button-selected-bg) !important;
    border-color: var(--color-filter-button-selected-border);

    a {
      color: inherit;
    }
  }

  .material-icons {
    width: 24px;
    height: 24px;

    &:empty {
      &:after {
        content: 'arrow_drop_down';
      }
    }
  }
}

.filter-items {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 4px;
}

.filter-item {
  border-radius: var(--radius-100);
  cursor: pointer;
  color: var(--color-filter-item-text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-filter-item-hover-bg);
  }

  &--selected {
    background-color: var(--color-filter-item-selected-bg) !important;
  }
}

.mdc-menu-surface--open ~ button {
  border-color: var(--primary-color) !important;

  .material-icons {
    &:empty {
      &:after {
        content: 'arrow_drop_up';
      }
    }
  }
}

.date-filter {
  [data-flatpickr-inline] {
    height: 320px;

    .flatpickr-calendar.inline {
      border: 0;
      box-shadow: none;
      top: 16px;
      right: 16px;
    }
  }

  input {
    display: none;
  }
}

.woots {
  .filter {
    border-radius: 0;
  }
}

