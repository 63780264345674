.notes-dialog {
  max-height: 90vh;
  min-height: 750px;
}

.note {
  background-color: $white;
  border: 1px solid #D1D5DA;
  border-radius: var(--radius-050);
  padding: 16px;
}

.high-contrast {
  .note {
    background-color: $eerie-black;
    border: $high-contrast-color;
  }
}
