.mdc-deprecated-list-item {
  a {
    padding: 12px 0;
    width: 100%;
  }
}

.mdc-menu-trigger {
  cursor: pointer;
}
