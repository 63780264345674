.quiz-notes {
  background-color: $gainsboro;
  border-left: 1px solid $gainsboro;
  height: calc(100vh - 64px - 32px);
  overflow-y: auto;
  padding: 16px;
}

.high-contrast {
  .quiz-notes {
    background-color: $high-contrast-background-color;
    border-color: $high-contrast-color;
  }
}
