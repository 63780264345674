.redactor-variables-list {
  list-style:none;
  margin:0;
  padding:0
}
.redactor-variables-list li {
  display:inline-block;
  margin-right:4px;
  margin-bottom:4px
}
.redactor-variables-list span {
  white-space:nowrap;
  background:rgba(0,125,255,.75);
  color:#fff;
  display:inline-block;
  padding:3px 6px;
  line-height:1;
  border-radius:4px;
  cursor:pointer
}
.redactor-variables-list span.redactor-variables-item-selected {
  background:#ee698a
}

