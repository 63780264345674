.redactor-inline-wrapper {
  flex-grow: 1;

  .redactor-styles {
    font-size: 16px;
    line-height: 24px;
    min-height: 40px;
    padding: 8px 48px 8px 16px;
    width: 100%;
  }

  .redactor-dialog-trigger {
    height: 24px;
    width: 24px;
    top: 8px;
    right: 16px;
    border-radius: 4px;

    &:hover {
      background-color: var(--color-redactor-dialog-trigger-hover-bg);
    }

    &:active {
      background-color: var(--color-redactor-dialog-trigger-active-bg);
    }
  }

  .material-icons {
    color: var(--color-redactor-icon);
    font-size: 24px;
  }

  .redactor-inline__buttons {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 16px;
  }

  .redactor-in {
    &:not(:focus-within):hover {
      background-color: var(--color-redactor-hover-bg) !important;
    }
  }
}

.re-button {
  i {
    &.material-icons {
      font-size: 16px;
    }
  }
}

.redactor-box {
  border-color: var(--color-border-default) !important;
  border-radius: var(--radius-100) !important;
  box-sizing: border-box;

  &.redactor-styles-on {
    background-color: var(--color-bg-default) !important;

    &.redactor-focus {
      border-color: var(--color-redactor-border) !important;

      &:focus-within {
        border-color: var(--color-secondary-700) !important;
        border-width: 1px !important;
      }

      &.invalid {
        border: 1px solid #CB212F !important;
      }
    }
  }


  &.invalid {
    border: 1px solid #CB212F !important;
  }
}

.redactor-statusbar {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.redactor-toolbar,
.redactor-air {
  z-index: 5;
}

.redactor-dialog-trigger {
  align-items: center;
  display: flex;
  height: 27px;
  position: absolute;
  right: 3px;
  top: 0;

  i {
    font-size: 20px;
  }
}

.redactor-modal {
  transition: margin 700ms;

  &[dir='rtl'] .redactor-modal-footer button.redactor-button-unstyled {
    margin-left: 4px;
  }

  .redactor-modal-body {
    max-height: calc(90vh - 200px);
  }

  .redactor-modal-footer {
    text-align: right;
  }
}

.text-editor-inline {
  .text-editor-inline__buttons {
    align-items: center;
    display: none;
    gap: 8px;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 16px;
  }

  &:focus-within {
    .text-editor-inline__buttons {
      display: flex;
    }
  }
}

.redactor-content {
  overflow-wrap: break-word;

  &::after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
  }

  h1:empty,
  h2:empty,
  h3:empty,
  h4:empty,
  h5:empty,
  h6:empty,
  p:empty,
  blockquote:empty,
  div[data-redactor-tag=br]:empty,
  div[data-redactor-tag=tbr]:empty {
    min-height: 1.5em;
  }
}

.redactor-styles,
.redactor-content {
  &.upload-redactor-error {
    background-color: inherit;
  }

  div {
    min-height: 1em;
  }

  [data-redactor-type='table'] {
    padding: 0 !important;
  }

  p, dl, blockquote, hr, pre, table, figure, address {
    margin: 0;
  }

  code {
    background-color: #EFF1F2;
    position: relative;
  }

  pre {
    display: block;
    white-space: pre-wrap;

    code {
      background-color: initial;
      padding: 0 4px !important;
    }
  }

  table {
    background-color: var(--color-bg-default);
    border: 1px solid rgba(0,0,0,.12);
    border-collapse: collapse;
    border-radius: var(--radius-050);
    color: $text-color;
    max-width: 100%;
    width: 100%;

    caption {
      color: rgba(0, 0, 0, 0.5);
      font-size: 11px;
      padding: 0;
      text-transform: uppercase;
    }

    th,
    td {
      padding: 16px;
    }

    tr {
      border-top: 1px solid rgba(0,0,0,.12);

      &:hover {
        background-color: rgba(0,0,0,.04);
      }
    }
  }

  thead {
    th {
      color: $text-color;
      font-weight: bold;
      text-align: left;
    }
  }

  tfoot {
    th,
    td {
      color: rgba(0, 0, 0, .5);
    }
  }

  figure {
    margin: 0;
  }

  img {
    height: auto;
    max-width: 100% !important;
  }

  ul, ol {
    margin: 0 0 0 1.5em !important;
    padding-left: 10px !important;
  }

  ol {
    ol {
      li {
        list-style-type: lower-alpha;
      }

      ol {
        li {
          list-style-type: lower-roman;
        }
      }
    }
  }
}

#redactor-filemanager-list span.r-file-size {
  top: 2px;
}

.redactor-component-active {
  &[data-redactor-type='drawing'],
  &[data-redactor-type='video'] {
    outline: none !important;

    img,
    video {
      outline: 5px solid rgba(0, 125, 255, 0.5) !important;
    }
  }
}

.high-contrast {
  .redactor-dropdown {
    background-color: $high-contrast-background-color;

    .redactor-dropdown-not-close {
      &.active {
        background-color: $high-contrast-background-color !important;
        color: $high-contrast-color !important;
      }

      &:hover {
        background-color: $shark;
      }
    }

    a {
      background-color: $high-contrast-background-color;
      color: $high-contrast-color !important;
    }

    span {
      border-color: $high-contrast-background-color !important;
    }
  }

  .redactor-modal {
    background-color: $high-contrast-background-color;
    color: $high-contrast-color;

    .redactor-modal-header {
      color: $high-contrast-color;
    }

    button,
    input,
    label {
      background-color: $high-contrast-background-color;
      border-color: $high-contrast-color;
      color: $high-contrast-color;
    }

    .upload-redactor-box {
      background-color: $high-contrast-background-color;
      border-color: $high-contrast-color;

      .upload-redactor-placeholder {
        color: $high-contrast-color;
      }
    }
  }

  .redactor-box {
    border-color: $high-contrast-color !important;

    .re-button-icon,
    .re-button,
    .redactor-styles {
      background-color: $high-contrast-background-color;
      color: $high-contrast-color;
    }

    &.redactor-styles-on {
      &.redactor-focus {
        border-color: $high-contrast-color !important;

        &:focus-within {
          border-color: white !important;
        }
      }
    }

    .redactor-statusbar {
      background-color: $high-contrast-background-color;

      li {
        color: $high-contrast-color;
      }
    }
  }

  .hover-effect {
    color: $high-contrast-color !important;
  }

  table {
    color: $high-contrast-color;

    td {
      border: 1px solid $high-contrast-color;
    }
  }
}

.response-fill {
  .redactor-box {
    display: inline-flex;
    flex-direction: row-reverse;
    height: 36px;
    justify-content: space-between;
    padding: 0;
    vertical-align: middle;
    width: 100%;
  }

  .redactor-styles {
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    padding: 6px 0 0 0;
    scrollbar-width: none;
    white-space: nowrap;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .re-button {
    margin-top: 5px;
    padding: 4px 6px 3px !important;
  }

  .redactor-toolbar {
    max-height: 36px;
    padding: 0 !important;
  }
}

body {
  .redactor-mention {
    @extend .mention;
  }

  .redactor-toolbar {
    background-color: var(--color-bg-100);

    padding: 8px 16px !important;
  }

  .redactor-toolbar-wrapper,
  .redactor-toolbar {
    border-top-left-radius: var(--radius-100);
    border-top-right-radius: var(--radius-100);
  }

  .redactor-toolbar {
    @extend .d-flex;
    @extend .flex-grow;
    @extend .flex-wrap;
    @extend .flex-items-center;
    @extend .gap-1;
  }

  .redactor-editor-wrapper {
    @extend .flex-items-center;
    @extend .gap-1;
    @extend .mr-3;

    display: flex;
  }

  .redactor-source-view {
    .redactor-editor-wrapper {
      display: none;
    }
  }

  .re-button {
    background-color: transparent;
    border-radius: var(--radius-050);
    box-shadow: none !important;
    box-sizing: border-box;
    color: var( --color-text-800) !important;
    height: 28px;
    margin: 0;
    max-height: 28px;
    padding: 4px !important;
    line-height: 20px;
  }

  .re-button-icon {
    @extend .d-inline-flex;
    @extend .flex-justify-center;

    max-width: 28px;
    width: 28px;
    font-size: 20px !important;

    .material-icons {
      font-size: 20px;
    }
  }

  .re-button-icon:hover {
    background-color: transparent;
  }

  .re-button:hover {
    background-color: var(--color-bg-200);
  }

  .redactor-button--active,
  .redactor-button-active,
  .re-button--active {
    background-color: var(--color-secondary-100) !important;
  }

  .re-button--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .redactor-dropdown {
    font-family: var(--font-family-text);
  }

  .redactor-dropdown-button {
    align-items: center;
    border-radius: 4px;
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    height: 28px;
    justify-content: center;
    line-height: 20px;
    padding: 4px;
    width: 28px;

    &--active,
    &:hover {
      border-color: var(--color-bg-200);
    }
  }

  .redactor-styles {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    padding: 8px 16px;
    min-height: 40px;

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    a,
    a:hover {
      color: var(--color-info-700);
    }

    blockquote {
      border-inline-start: 4px solid var(--color-bg-200 );
      color: var(--color-text-900);
      font-style: normal;
      padding-left: 16px;
    }
  }

  .re-bold { order: 1 };
  .re-italic { order: 2 };
  .re-underline { order: 3 };
  .re-fontcolor, .re-color { order: 4 };
  .re-format, .re-heading { order: 5 };
  .re-blockquote { order: 6 };
  .re-link { order: 7 };
  .re-ol { order: 8 };
  .re-ul, .re-indent, .re-outdent { order: 9 };
  .re-upload, .re-image, .re-file { order: 10 };
  .re-twemoji { order: 11 };
  .re-audio, .re-record { order: 12 };
  .re-table { order: 13 };
  .re-alignment { order: 14 };
  .re-sub { order: 15 };
  .re-sup { order: 16 };
  .re-pre { order: 17 }
  .re-specialchars { order: 18 };
  .re-equation { order: 19 };
  .re-html { order: 20 };
  .re-variable { order: 21 };
  .re-shortcut { order: 22 };

  .re-undo,
  .re-redo {
    order: 99;
  }

  .re-undo {
    margin-left: auto !important;
  }

  .redactor--disabled {
    .redactor__inline_button,
    .redactor-toolbar-wrapper {
      display: none !important;
    }
  }

  .redactor--no-toolbar {
    .redactor-toolbar {
      display: none !important;
    }
  }

  .redactor:not(.redactor--disabled) {
    .redactor-box:not(:focus-within):hover {
      background-color: var(--color-redactor-hover-bg) !important;
    }
  }

  .redactor__inline_button {
    align-self: flex-end;
    margin: 8px 0;
  }
}
