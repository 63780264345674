.side-nav {
  background-color: transparent;
  display: flex;
  flex: 0 1 auto;
  margin-bottom: 24px;
  overflow-x: hidden;
  position: relative;
}

.side-nav-item {
  -webkit-flex: 0 1 auto;
  align-items: center;
  background-color: initial;
  border-bottom: 1px solid var(--color-border-default);
  border-right: 1px solid var(--color-border-default);
  border-top: 1px solid var(--color-border-default);
  box-sizing: border-box;
  color: var(--color-fg-default);
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
  text-align: left;

  &:first-child {
    border-bottom-left-radius: var(--radius-100, 8px);
    border-left: 1px solid var(--color-border-default);
    border-top-left-radius: var(--radius-100, 8px);
  }

  &:last-child {
    border-bottom-right-radius: var(--radius-100, 8px);
    border-top-right-radius: var(--radius-100, 8px);
  }

  &.active {
    background-color: var(--color-action-list-item-default-active-bg);
    border-color: var(--color-action-list-item-default-active-bg);
  }

  &:not(.active):hover {
    background-color: var(--color-action-list-item-default-hover-bg);
    cursor: pointer;
  }
}
