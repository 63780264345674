:root {
    &[data-color-mode='dark'] {
        --color-action-list-item-inline-divider: rgba(68,76,86,0.48);
        --color-action-list-item-default-hover-bg: rgba(144,157,171,0.12);
        --color-action-list-item-default-hover-border: rgba(0,0,0,0);
        --color-action-list-item-default-active-bg: rgba(144,157,171,0.2);
        --color-action-list-item-default-active-border: rgba(0,0,0,0);
        --color-action-list-item-default-selected-bg: rgba(144,157,171,0.08);
        --color-border-default: #444c56;
        --color-border-muted: #373e47;
        --color-border-subtle: rgba(205,217,229,0.1);
        --color-canvas-default: #22272e;
        --color-canvas-overlay: #2d333b;
        --color-canvas-inset: #1c2128;
        --color-canvas-subtle: #2d333b;
        --color-fg-default: #adbac7;
        --color-fg-muted: #768390;
        --color-fg-subtle: #636e7b;
        --color-fg-on-emphasis: #cdd9e5;
        --color-header-text: rgba(205,217,229,0.7);
        --color-header-divider: #768390;
        --color-page-header-bg: #22272e;
        --color-table-row-hover-bg: rgba(144,157,171,0.12);
        --color-table-row-selected-bg: rgba(64, 0, 221, 0.48);
    }
}
