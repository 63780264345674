.timeline {
  position: relative;

  &:before {
    background-color: var(--color-border-default);
    content: '';
    height: 100%;
    left: 96px;
    position: absolute;
    top: 0;
    width: 1px;
    z-index: -1;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .log-change {
    &:last-child {
      .border-bottom {
        border-bottom: none !important;
      }
    }
  }

  .log-change-separator {
    width: 10px;

    &::before, &::after {
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }

    &::before {
      background-color: var(--color-border-default);
      clip-path: polygon(1px 0%, 100% 50%, 1px 100%, 0% 100%, calc(100% - 1px) 50%, 0% 0%);
    }

    &::after {
      background-color: var(--color-canvas-inset);
      clip-path: polygon(0% 0%, calc(100% - 1px) 50%, 0% 100%, 0% 100%, 0% 50%, 0% 0%);
    }
  }
}
