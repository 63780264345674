.blankslate {
  @extend .d-flex, .flex-column, .gap-2;

  padding: 40px;
  text-align: center;
  width: -webkit-fill-available;
}

.blankslate__title,
.blankslate__text {
  @extend .text-title;
  @extend .f4;
  font-weight: 600;
}

.blankslate__description {
  @extend .text-support;
  @extend .f5;
}

.blankslate__title,
.blankslate__text,
.blankslate__image,
.blankslate__description {
  align-self: center;
  max-width: 800px;
}
