.menu {
  list-style: none;
  margin-bottom: 15px;
}

.mdc-menu-surface {
  border-radius: var(--radius-100);
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 8px 0px rgba(36, 41, 47, 0.05);
}

.menu-heading {
  color: var(--color-fg-muted);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 6px 8px;
}

.menu-item {
  border-radius: var(--radius-050);
  color: var(--color-fg-default);
  display: block;
  margin-bottom: 4px;
  padding: 6px 8px;
  position: relative;

  [data-js-collapsible-indicator] {
    margin: 0 !important;
  }

  &.active {
    background-color: var(--color-action-list-item-default-active-bg);
    font-weight: 600;
  }

  &:not(.active):hover {
    background-color: var(--color-action-list-item-default-hover-bg);
    cursor: pointer;
  }

  &.active:before {
    background-color: var(--primary-color);
    border-radius: 2px;
    bottom: 0;
    content: "";
    left: -6px;
    position: absolute;
    top: 0;
    width: 2px;
  }
}

.woots {
  .menu-item {
    border-radius: 0;

    &.active:before {
      border-radius: 0;
    }
  }
}
