[data-redactor-type='audio'] {
  height: 64px;
  padding: 5px;
}

.audio-recording {
  position: relative;
}

.audio-recording #visualizer {
  background-color: #fafafa;
  width: 100%;
}

.audio-recording .time {
  background-color: black;
  color: white;
  height: 16px;
  line-height: 16px;
  position: absolute;
  top: 103px;
}

.audio-recording #time {
  left: 0;
}

.audio-recording #max-time {
  right: 0;
}

.audio-recording .start,
.audio-recording .pause,
.audio-recording .resume,
.audio-recording .stop {
  float: none;
}

.audio-recording .start i,
.audio-recording .pause i,
.audio-recording .resume i,
.audio-recording .stop i {
  float: left;
  font-size: 18px;
}

.audio-recording .pause,
.audio-recording .resume,
.audio-recording .stop {
  display: none;
}

.audio-recording #warning {
  color: red;
  display: none;
  text-align: center;
}
