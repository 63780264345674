.log-wrapper {
  border-left: 1px solid $platinum;
}

.log-item {
  position: relative;

  &:before {
    background-color: $dove-gray;
    border-radius: 50%;
    content: '';
    height: 8px;
    left: -4.5px;
    position: absolute;
    top: 6px;
    width: 8px;
  }

  p {
    margin: 0;
  }
}
