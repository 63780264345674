.mdc-icon-button--dense {
  @include icon-button.density(-2);

  color: var(--color-icon-button);
}

.mdc-icon-button {
  &.mdc-button {
    min-width: initial;
  }
}

.mdc-icon-button--square {
  .mdc-icon-button__ripple {
    &:after,
    &:before {
      border-radius: 0;
    }
  }
}

.mdc-icon-button--grey {
  background-color: #B9B9B9;
  color: white;
  border-radius: 100%
}

.high-contrast {
  .mdc-icon-button {
    @include icon-button.ink-color($high-contrast-color);

    color: $high-contrast-color;
  }
}
