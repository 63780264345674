.truncate {
  // flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-break-word {
  word-break: break-word;
}

.line-clamp-2 {
  @extend .truncate;

  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
