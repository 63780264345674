.flash {
  background-color: #FCE4D8;
  border-bottom: #F9CAB0 1px solid;
  color: $jet;
  font-family: var(--font-family-header);
  z-index: 6;

  .flash-link {
    color: #023B7B;
    font-family: var(--font-family-header);

    &:hover {
      color: $celtic-blue;
    }
  }

  .flash-icon-button {
    color: #023B7B;
  }

  &.flash--archived {
    background-color: #F9CAB0;
  }
}
