.flatpickr-calendar {
  border: 1px solid var(--color-border-default);
  border-radius: var(--radius-050);
  box-shadow: none;
  width: calc(307.875px + 16px);
  -webkit-box-shadow: none;

  .flatpickr-months,
  .flatpickr-prev-month,
  .flatpickr-next-month,
  .flatpickr-innerContainer {
    margin: 8px;
  }

  .flatpickr-day {
    &:hover {
      background: rgba(88, 48, 255, 0.08);
      border-color: transparent;
    }
    &.today {
      color: var(--color-fg-default);
      border-color: var(--primary-color);
    }
    &.selected {
      font-weight: 600 !important;
      background: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      color: white !important;
    }
  }


  .flatpickr-months {
    .flatpickr-prev-month:hover,
    .flatpickr-next-month:hover {
      svg {
        fill: #000;
      }
    }
  }
}

[data-flatpickr-inline] {
  height: 424px;
  width: 324px;

  .flatpickr-calendar.inline {
    top: calc(100% + 8px);
    right: calc(100% - 324px);
    position: absolute;
  }
}

