.mdc-fab {
  @include fab.container-color($peach-crayola);
  @include fab.ink-color($navy-blue);
  @include fab.shape-radius(4);

  font-family: Poppins, sans-serif;
  font-weight: 500;
  letter-spacing: normal;
  text-transform: none;
}

// Woots overwrites
.woots {
  .mdc-fab {
    @include fab.ink-color($heading-color);
    @include fab.container-color($mountain-meadow);

    font-family: 'Jost', sans-serif;
  }
}
