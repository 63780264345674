.select-button {
  align-items: center;
  background: var(--color-select-button-bg);
  border: 1px solid var(--color-border-default);
  border-radius: var(--radius-100);
  box-sizing: border-box;
  color: var(--color-select-button-text);
  cursor: pointer;
  display: inline-flex;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  padding: 8px;

  &--selected {
    background: var(--color-select-button-selected-bg) !important;
  }

  &:hover {
    background: var(--color-select-button-hover-bg);
  }
}
