.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.list-item {
  border-radius: 8px;
  color: var(--color-list-item-text);
  display: block;
  padding: 8px 16px;

  &--selected,
  &:focus {
    background-color: var(--color-list-item-selected-bg);
  }

  &:not(&--selected):hover {
    background-color: var(--color-list-item-hover-bg);
  }
}

.list:has(> *) + .blankslate {
  display: none !important;
}
