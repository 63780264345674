.response-hotspot,
.response-hotmatch {
  counter-reset: areas;

  .hotspot-placeholders {
    background-color: #EEE;
    border: 1px solid #D1D5DA;
    border-radius: var(--radius-050);
    margin-bottom: 20px;
    padding: 10px 0 10px 10px;
  }

  .hotspot-placeholder {
    min-height: 25px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &::after {
      background-color: #EEE;
      border: 2px dashed black;
      content: counter(areas);
      counter-increment: areas;
      height: 25px;
      left: 0;
      line-height: 25px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 25px;
    }
  }

  .hotspot-marker {
    background-color: rgba(255, 255, 255, .5);
    border: 2px solid black;
    border-radius: 5px;
    color: black;
    height: 25px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 0;
    touch-action: none;
    user-select: none;
    width: 25px;
    z-index: 5;

    &.selected {
      background-color: black;
      border-color: rgba(255, 255, 255, .5);
      color: white;
    }
  }

  .hotspot-drop-area {
    border: 1px solid #D1D5DA;
    border-radius: var(--radius-050);
    padding: 10px 10px 5px;
  }

  .hotspot-image-wrapper {
    max-height: 1000px;
    max-width: 1000px;
    position: relative;
  }

  .image-mapper-svg {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}


.high-contrast {
  .hotspot-placeholders,
  .hotspot-drop-area,
  .hotspot-marker {
    background-color: $high-contrast-background-color;
    border-color: $high-contrast-color;
    color: $high-contrast-color;
  }

  .hotspot-placeholder {
    &::after {
      background-color: $high-contrast-background-color;
      border-color: $high-contrast-color;
      color: $high-contrast-color;
    }
  }
}
