:root {
    &[data-color-mode='light'] {
        --color-action-list-item-inline-divider: rgba(208,215,222,0.48);
        --color-action-list-item-default-hover-bg: rgba(208,215,222,0.32);
        --color-action-list-item-default-hover-border: rgba(0,0,0,0);
        --color-action-list-item-default-active-bg: rgba(208,215,222,0.48);
        --color-action-list-item-default-active-border: rgba(0,0,0,0);
        --color-action-list-item-default-selected-bg: rgba(208,215,222,0.24);
        --color-border-default: #E8E8E8;
        --color-border-muted: hsla(210,18%,87%,1);
        --color-border-subtle: rgba(27,31,36,0.15);
        --color-canvas-default: #ffffff;
        --color-canvas-inset: #fcfcff;
        --color-canvas-overlay: #ffffff;
        --color-canvas-subtle: #f6f8fa;
        --color-fg-default: #24292f;
        --color-fg-muted: #57606a;
        --color-fg-on-emphasis: #ffffff;
        --color-fg-subtle: #6e7781;
        --color-header-text: rgba(255,255,255,0.7);
        --color-header-divider: #57606a;
        --color-page-header-bg: #f6f8fa;
        --color-table-row-hover-bg: rgba(208,215,222,0.32);
        --color-table-row-selected-bg: rgba(88, 48, 255, .08);
    }
}
