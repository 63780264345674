#redactor-handle-list {
  font-family: "Trebuchet MS", 'Helvetica Neue', Helvetica, Tahoma, sans-serif;
  display: none;
  position: absolute;
  z-index: 1053;
  background-color: #FFF;
  box-shadow: 0 4px 14px rgba(0, 0, 0, .2);
  border-radius: 2px;
  width: 264px;
  max-height: 250px;
  margin: 0;
  overflow: auto;
  font-size: 14px;
  padding: 0;
}

#redactor-handle-list a {
  display: block;
  text-decoration: none;
  padding: 10px 8px;
  white-space: nowrap;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}

#redactor-handle-list a.selected {
  background-color: #EEE !important;
}

#redactor-handle-list a:hover {
  color: #FFF !important;
  background-color: #449aef !important;
}

.redactor-mention {
  background-color: rgba(242, 199, 68, 0.4);
  color: rgb(18, 100, 163);
  padding: 0 2px 1px 2px;
  border-radius: 3px;
}
