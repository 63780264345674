.relation {
  border-radius: var(--radius-050);
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  margin: 2px 1px 2px 0;
  padding: 3px 6px;

  .relation--block {
    display: inline-block;
    height: 8px;
    width: 8px;
  }

  .relation--counter {
    font-size: 11px;
    margin-left: 2px;
  }

  &.relation--enemy {
    background-color: rgba(255,0,0,.1);

    .relation--block {
      background-color: #c33;
    }

    .relation--counter {
      color: #c33;
    }
  }

  &.relation--relative {
    background-color: #EBF3FF;

    .relation--block {
      background-color: #2C79FF;
    }

    .relation--counter {
      color: #2C79FF;
    }
  }
}

