@each $key, $value in $units {
  .p-#{$key} {
    padding: $value !important;
  }

  .pb-#{$key}, .py-#{$key} {
    padding-bottom: $value !important;
  }

  .pl-#{$key}, .px-#{$key} {
    padding-left: $value !important;
  }

  .pr-#{$key}, .px-#{$key} {
    padding-right: $value !important;
  }

  .pt-#{$key}, .py-#{$key} {
    padding-top: $value !important;
  }
}

@media (max-width: 599px) {
  .px-6 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .phone-pr-3 {
    padding-right: 16px !important;
  }

  .phone-pl-3 {
    padding-left: 16px !important;
  }

  .phone-py-4 {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }

  .phone-p-6 {
    padding: 40px !important;
  }
}
