.text-title {
  color: var(--color-text-default-text);
  font-family: var(--font-family-text);
  font-weight: 600;
}

.text-body {
  color: var(--color-text-900);
}

.text--danger {
  color: var(--color-danger-600);
}

.text-small {
  color: var(--color-text-default-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-support {
  color: var(--color-text-support-text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
