.mdc-tab {
  height: 40px;
  padding: 0 16px;
  text-transform: none !important;

  &:disabled {
    pointer-events: none;
  }
}

.mdc-tab__text-label--truncate {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mdc-tab--head {
  @include tab.text-label-color(var(--color-fg-default));
  @include tab.icon-color(var(--color-fg-muted));

  @include tab.active-text-label-color(var(--color-fg-default));
  @include tab.active-icon-color(var(--color-fg-muted));
  @include tab.states-color($shuttle-gray)
}

.high-contrast {
  .mdc-tab {
    @include tab.text-label-color($high-contrast-color);
    @include tab.icon-color($high-contrast-color);

    @include tab.active-text-label-color($high-contrast-color);
    @include tab.active-icon-color($high-contrast-color);
    @include tab.states-color($high-contrast-color);
  }
}
