.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-items-center {
  align-items: center !important;
}

.flex-items-start {
  align-items: flex-start !important;
}

.flex-items-end {
  align-items: flex-end !important;
}

.flex-items-left {
  align-items: left !important;
}

.flex-content-start {
  align-content: flex-start !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify-between {
  justify-content: space-between !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-evenly {
  justify-content: space-evenly !important;
}

.flex-justify-around {
  justify-content: space-around !important;
}

.flex-column {
  flex: 1 1 100%;
  flex-direction: column !important;
}

.flex-column2 {
  flex: 1 1 200%;
  flex-direction: column !important;
}

.flex-column3 {
  flex: 1 1 300%;
  flex-direction: column !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-auto {
  flex: auto !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}
