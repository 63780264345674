.mdc-tab-indicator--head {
  @include tab-indicator.underline-color(var(--color-fg-muted));
}

.mdc-tab-indicator {
  .mdc-tab-indicator__content--underline {
    border-top-width: 2px;
  }
}

.high-contrast {
  .mdc-tab-indicator {
    @include tab-indicator.underline-color($high-contrast-color);
  }
}
