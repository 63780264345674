.mdc-checkbox {
  // container-colors($unmarked-stroke-color, $unmarked-fill-color, $marked-stroke-color, $marked-fill-color, $generate-keyframes)
  @include checkbox.container-colors($eerie-black, transparent, $han-purple, $han-purple, $han-purple);
  @include checkbox.focus-indicator-color($han-purple);

  &.tooltip {
    pointer-events: fill !important;
  }

  .tooltip-text {
    padding: 12px !important;
  }

  &--disabled:hover {
    .mdc-checkbox__ripple:before,
    .mdc-checkbox.mdc-ripple-surface--hover
    .mdc-checkbox__ripple:before {
      opacity: 0;
    }
  }
}

// Woots overwrites
.woots {
  .mdc-checkbox {
    // container-colors($unmarked-stroke-color, $unmarked-fill-color, $marked-stroke-color, $marked-fill-color, $generate-keyframes)
    @include checkbox.container-colors($eerie-black, transparent, $azul, $azul, $azul);
    @include checkbox.focus-indicator-color($azul);
  }

  &.dyslexia {
    &:not(.high-contrast) {
      .mdc-checkbox {
        @include checkbox.container-colors($eerie-black, $ghost-white, $azul, $azul, $azul);
      }
    }
  }
}

.dyslexia {
  .mdc-checkbox {
    @include checkbox.container-colors($eerie-black, $ghost-white, $han-purple, $han-purple, $han-purple);
  }
}

.high-contrast {
  .mdc-checkbox {
    // container-colors($unmarked-stroke-color, $unmarked-fill-color, $marked-stroke-color, $marked-fill-color, $generate-keyframes)
    @include checkbox.container-colors($high-contrast-color, $high-contrast-background-color, $high-contrast-color, $high-contrast-background-color, $high-contrast-color);
    @include checkbox.focus-indicator-color($high-contrast-color);
    @include checkbox.ink-color($high-contrast-color)
  }
}
