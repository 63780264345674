.mdc-text-field {
  @include textfield.focused-outline-color($han-purple);
  @include textfield.ink-color(var(--color-fg-default));
  @include textfield.line-ripple-color($han-purple);
  @include textfield.placeholder-color(var(--color-fg-muted));

  &.mdc-text-field--focused {
    @include textfield.label-color($han-purple);
  }
}

.mdc-text-field--outlined {
  @include textfield.outline-color(#D1D1D1);
  @include textfield.hover-outline-color(#A2A2A2);

  margin-top: 8px;

  &:focus-within {
    @include textfield.outline-color(var(--primary-color));
    @include textfield.hover-outline-color(var(--primary-color));

    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-width: 2px;
      }
    }
  }
}

.mdc-text-field--full-width {
  width: 100%;

  @include textfield.fill-color(transparent);

  .mdc-text-field__input {
    width: 100%;
  }
}

.mdc-text-field--white {
  @include textfield.ink-color($white);
  @include textfield.outline-color($white);
  @include textfield.label-color($white);
  @include textfield.focused-outline-color($white);
  @include textfield.hover-outline-color($white);
  @include textfield.placeholder-color($white);
  @include textfield.caret-color($white);

  &.mdc-text-field--focused {
    @include textfield.label-color($white);
  }
}

.mdc-text-field__affix {
  font-size: 12px;
}

.woots {
  .mdc-text-field {
    @include textfield.focused-outline-color($azul);
    @include textfield.line-ripple-color($azul);

    &.mdc-text-field--focused {
      @include textfield.label-color($azul);
    }
  }

  .mdc-text-field--white {
    @include textfield.focused-outline-color($white);
  }
}

.high-contrast {
  .mdc-text-field {
    @include textfield.ink-color($high-contrast-color);
    @include textfield.outline-color($high-contrast-color);
    @include textfield.label-color($high-contrast-color);
    @include textfield.focused-outline-color(#fff);
    @include textfield.hover-outline-color($high-contrast-color);
    @include textfield.placeholder-color($high-contrast-color);
    @include textfield.caret-color($high-contrast-color);

    &.mdc-text-field--focused {
      @include textfield.label-color(#fff);
    }
  }
}
