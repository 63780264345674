.task-button {
  align-items: center;
  border: 1px solid $gainsboro;
  border-radius: var(--radius-050);
  display: flex;

  .task-button-title {
    border-right: 1px solid $gainsboro;
  }

  .task-button-title,
  .task-button-counter {
    padding: 4px 8px;
  }
}
