.user-icons {
  margin-left: 4px;

  .user-icon {
    margin-left: -4px;
  }
}

.user-icon {
  align-items: center;
  border-radius: 100%;
  border: 1px solid #FFF;
  box-sizing: border-box;
  color: #F4F6F7;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  line-height: 20px;
  min-height: 24px;
  min-width: 24px;
  width: 24px;

  &.user-icon--amber {
    background-color: #967302;
  }

  &.user-icon--blue {
    background-color: #02499A;
  }

  &.user-icon--cyan {
    background-color: #3290FC;
  }

  &.user-icon--deep-orange {
    background-color: #AD450C;
  }

  &.user-icon--deep-purple {
    background-color: #5830FF;
  }

  &.user-icon--green {
    background-color: #00A349;
  }

  &.user-icon--indigo {
    background-color: #190068;
  }

  &.user-icon--light-blue {
    background-color: #0366D6;
  }

  &.user-icon--light-green {
    background-color: #5FA180;
  }

  &.user-icon--lime {
    background-color: #08A873;
  }

  &.user-icon--orange {
    background-color: #ED6115;
  }

  &.user-icon--pink {
    background-color: #B4544A;
  }

  &.user-icon--purple {
    background-color: #8259FD;
  }

  &.user-icon--red {
    background-color: #BF1000;
  }

  &.user-icon--teal {
    background-color: #056A48;
  }

  &.user-icon--yellow {
    background-color: #E1AD02;
  }

  &.user-icon--disabled {
    background-color: #B9B9B9;
  }
}
