.dialog {
  @extend .border;
  @extend .elevation-1;

  padding: 0;
  z-index: 1001;
}

/* Prevent scrolling while dialog is open */
body:has(dialog[data-dialog-target='dialog'][open]) {
  overflow: hidden;
}

dialog::backdrop,
.dialog__backdrop {
  background-color: #000;
  opacity: .6;
}

.dialog__backdrop {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

dialog {
  color: inherit;
}

/* Add animations */
dialog[data-dialog-target='dialog'][open] {
  animation: fade-in 200ms forwards;
}

dialog[data-dialog-target='dialog'][closing] {
  animation: fade-out 200ms forwards;
}


.dialog__container {
  @extend .rounded-1;
  @extend .d-flex;
  @extend .flex-column;
  @extend .gap-4;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: var(--color-bg-050);
  min-width: 440px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  padding: 24px;
}

.dialog__content {
  @extend .d-flex;
  @extend .flex-column;
  @extend .gap-3;
}

.dialog__header {
  @extend .d-flex;
  @extend .flex-items-center;
  @extend .flex-justify-between;
  @extend .border-bottom;
  @extend .pb-1;

  box-sizing: border-box;
  height: 40px;
}
