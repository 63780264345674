.todo {
  border-radius: var(--radius-100);
  position: relative;

  .todo__handle {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .todo__delete {
    visibility: hidden;
  }

  .todo__notes {
    @extend .material-icons;

    color: var( --color-bg-300);
  }

  &:hover {
    background-color: var(--color-bg-150);

    .todo__handle {
      display: flex;
    }

    .todo__delete {
      visibility: visible;
    }

    .user-icon {
      border-color: var(--color-bg-150);
    }
  }
}
