.responsive-img {
  height: auto;
  max-width: 100%;
}

.responsive-video {
  height: auto;
  max-width: 100%;
}

.play-limit {
  display: flex;
  align-items: center;
  background: #F5F5F5;
}

.high-contrast {
  .play-limit {
    background-color: $gunmetal;
  }
}
