.question {
  background-color: #F7F8FA;
  border: 1px solid #F7F8FA;
  border-radius: var(--radius-050);
  max-width: 1000px;
  min-height: 100px;
  page-break-inside: avoid;
}

.woots {
  .question {
    border-radius: 0;
  }
}
