.time {
  input {
    width: 50px;
  }
}

.duration {
  input {
    width: 50px;
  }
}
