.cookie-wrapper {
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 8px;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.cookie-notice {
  background-color: #f5f5f5;
  border-radius: 24px;
  margin: 0 16px;
  padding: 24px;
}
