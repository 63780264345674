.inline-banner {
  border-radius: var(--radius-100);
  color: #171717;
  margin-bottom: 24px;
  padding: 16px 24px;

  &:has(.material-icons) {
    padding-left: 18px;
  }

  &--condensed {
    @extend .inline-banner;
    font-size: 12px;
    padding: 8px 16px;
  }

  &--warning {
    @extend .inline-banner;
    background-color: #FFF7DC;
  }

  &--info {
    @extend .inline-banner;
    background-color: #F0F8FF;
  }

  &--grey {
    @extend .inline-banner;
    background-color: #F5F5F5;
  }
}

.inline-banner__icon {
  color: #464646;
  font-size: 32px !important;
  margin-right: 16px;
}
