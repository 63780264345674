.version-item {
  background-color: #F6F8FA;
  border-radius: var(--radius-050);
  padding: 8px 16px;

  &.version-item--after {
    border-left: 3px solid #0BE49C;
  }

  &.version-item--before {
    border-left: 3px solid $light-coral;
  }
}
