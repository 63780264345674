$units: (0 0, 1 4px, auto auto);
@for $i from 2 through 13 {
  $units: append($units, ($i ($i * 8px - 8px)));
}

$heading-color: #191919;
$text-color: #484848;

$high-contrast-color: #FFDE41;
$high-contrast-background-color: #212121;

$alabaster: #FAFAFA;
$atomic-tangerine: #FFA574;
$azul: #2664EF;
$black: #000;
$blue:#0000FF;
$cardinal: #DD2C3B;
$celtic-blue: #0366D6;
$charleston-green: #24292E;
$cultured: #EEEEEE;
$cyan: #00FFFF;
$dark-orange: #FF8C00;
$davys-grey: #595959;
$deep-pink: #FF1493;
$dove-gray: #666;
$eerie-black: #212121;
$forest-green-web: #238636;
$gainsboro: #E1E4E8;
$ghost-white: #F2F1F8;
$green-crayola: #00B47B;
$green: #008000;
$han-purple: #5830FF;
$indigo: #4B0082;
$jade: #00B87B;
$jasmine: #FFE082;
$jet: #2E2E2E;
$jungle-green: #35A889;
$lavender-blue: #D4CBFF;
$light-blue: #ADD8E6;
$light-coral: #FF8282;
$light-gray: #d1d5da;
$light-salmon: #FFA074;
$lime: #00FF00;
$maximum-yellow-red: #E3B341;
$mikado-yellow: #FFC100;
$mountain-meadow: #3BBA98;
$navy-blue: #190068;
$orange: #FFA500;
$pale-sky: #6A737D;
$peach-crayola: #FFBF9D;
$platinum: #E5E5E5;
$shark: #24292E;
$shuttle-gray: #586069;
$tart-orange: #F85149;
$turquoise-green: #95D5B2;
$white: #FFF;

// Woots colors
$azul: #2664EF;
$black: #000000;
$cloudy-blue: #A9D8DA;
$grapefruit-hover: #CE5050;
$grapefruit: #EF5E5E;
$green-blue-hover: #009676;
$green-blue: #00BD95;
$gunmetal: #555657;
$light-peach: #F9BFBF;
$light-periwinkle: #D0DDF9;
$light-pink: #F1EEEF;
$maize: #FFDE41;
$pale-2: #FAE1D1;
$pale: #E4E1E0;
$parchment: #FFF2B3;
$pastel-blue: #A8C1F9;
$very-light-pink-2: #F3EBE9;
$very-light-pink: #FAF7F6;
$white: #FFFFFF;

// Integrations
$proctorio: #7DC953;
