input, select {
  font-family: var(--font-family-text);
}

.text-field {
  border-radius: var(--radius-100);
  border: 1px solid var(--color-border-default);
  box-sizing: border-box;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  outline: none;
  padding: 8px 16px;

  &:focus-within {
    border-color: var(--color-secondary-700);
  }
}

.text-field::placeholder {
  color: var(--color-text-support-text);
  opacity: 1; /* Firefox */
}

.text-field::-ms-input-placeholder {
  color: var(--color-text-support-text);
}

.position-field {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid white;
  box-sizing: border-box;
  color: white;
  height: 100%;
  outline: none;
  padding: 0;
  text-align: center;

  // Chrome, Safari, Edge, Opera
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.select-field {
  border-radius: var(--radius-100);
  border: 1px solid var(--color-border-default);
  box-sizing: border-box;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  outline: none;
  padding: 8px 40px 8px 16px;
  appearance: none; /* Hides default dropdown arrow */
  background: var(--color-background, #fff) url('images/select_up_down_arrow.svg') no-repeat right 16px center;

  &:disabled {
    background: var(--color-background, #fff) url('images/select_up_down_arrow_disabled.svg') no-repeat right 16px center;
  }
}

.select-field::placeholder {
  color: var(--color-text-support-text);
  opacity: 1; /* Firefox */
}

.chips-field {
  @extend .text-field;

  @extend .d-flex;
  @extend .flex-items-center;
  @extend .flex-wrap;
  @extend .gap-1;
  @extend .px-3;
  @extend .py-2;

  height: auto;
}

.chips-field__dropdown {
  @extend .dropdown;
  @extend .flex-grow;
}

.chips-field__search {
  @extend .text-field;

  @extend .border-none;
  @extend .f4;
  @extend .p-0;
  @extend .width-full;

  height: 28px;
}
