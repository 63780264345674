.chip {
  align-items: center;
  background: var(--color-chip-default-bg);
  border-radius: 360px;
  border: 1px solid var(--color-chip-default-border);
  box-sizing: border-box;
  color: var(--color-chip-default-text);
  display: inline-flex;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  gap: 4px;
  height: 28px;
  justify-content: center;
  line-height: 20px;
  padding: 4px 8px;
  white-space: nowrap;
  width: fit-content;

  &--small {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
  }

  .chip__icon {
    @extend .material-icons;
    color: var(--color-chip-default-icon);
    font-size: 16px !important;
    line-height: 16px;
    width: 16px !important;
    height: 16px;

    &__trailing {
      @extend .chip__icon;
      cursor: pointer;
    }
  }
}

@each $type in ("chip-danger", "chip-info", "chip-progress", "chip-success", "chip-unknown", "chip-warning") {
  .#{$type} {
    @extend .chip;

    background: var(--color-#{$type}-bg);
    border-color: var(--color-#{$type}-border);
    color: var(--color-#{$type}-text);
  }
}

.chip-progress {
  &--clickable {
    &:hover {
      background: #F2F2F2;
    }

    &:active {
      background: var(--color-chip-progress-bg-active);
    }
  }
}

.chip-unknown {
  border-style: dashed;
}

.chip-hover {
  border-radius: var(--radius-100);
  padding: 4px;

  &:hover {
    background-color: #E8E8E8;
  }
}
