.border {
  border: 1px solid var(--color-border-default) !important;
  border-radius: var(--radius-100);
}

.border-top {
  border-top: 1px solid var(--color-border-default) !important;
}

.rounded-1 {
  border-radius: var(--radius-050) !important;
}

.rounded-2 {
  border-radius: var(--radius-100) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-top-1 {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-none {
  border: 0 !important;
}

.border-right {
  border-right: 1px solid var(--color-border-default) !important;
}

.border-left {
  border-left: 1px solid var(--color-border-default) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--color-border-default) !important;
}

.border--highlight {
  border-color: $han-purple !important;
  border-width: 2px !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

