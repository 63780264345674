.calculator-wrapper {
  border: 1px solid black;
  border-bottom: 0;
  border-radius: 3px;
  box-sizing: border-box;
  height: 400px;
  left: calc(50vw - 300px);
  position: absolute;
  top: calc(50vh - 200px);
  touch-action: none;
  width: 600px;
  z-index: 999;

  .drag-handle {
    border-radius: 3px;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    height: 38px;
    width: 100%;
  }

  .calculator {
    height: calc(100% - 40px);
    width: 100%;
  }

  // Fix for Chrome zoom
  .dcg-exp-mathquill-container {
    overflow: initial !important;
  }
}
