body {
  background-color: var(--color-canvas-default);
  color: var(--color-text-default-text);
  font-family: var(--font-family-text);
  font-size: 14px;
  line-height: 20px;
  --mdc-typography-font-family: var(--font-family-text);
  --mdc-typography-body1-letter-spacing: normal;

  &.woots {
    font-family: 'Montserrat', sans-serif;
    --mdc-typography-font-family: 'Montserrat', sans-serif;
  }
}

.large-font-size {
  body {
    font-size: 18px;
  }
}

.high-contrast {
  body {
    color: $high-contrast-color;
  }
}

li:first-child {
    margin-top: 0px !important;
}

button {
  all: unset;
  box-sizing: border-box;
}

button:focus {
  outline: revert;
}
