.container {
  margin: 40px auto 0;
  max-width: 700px;

  @media screen and (max-width: 778px) {
    max-width: 90vw;
  }
}

.large-container {
  margin: 0 auto;
  max-width: 1084px;
}

.woots-large-container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;

  @media only screen and (min-width: 993px) {
    width: 70%;
  }
}
