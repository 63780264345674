.support-button {
  align-items: center;
  background: var(--color-support-button-bg);
  border-radius: 8px;
  border: 1px dashed var(--color-support-button-border);
  box-sizing: border-box;
  color: var(--color-support-button-text);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  gap: 16px;
  padding: 16px;

  &--compact {
    padding: 8px 16px;
  }

  &:hover {
    background: var(--color-support-button-hover-bg);
  }

  &:active {
    background: var(--color-support-button-active-bg);
  }

  .material-icons {
    color: var(--color-support-button-icon)
  }
}
