.table-container {
  @extend .border;

  overflow-x: auto;
}

.table {
  @extend .width-full;

  border-spacing: 0;
}

.table__header {
  @extend .position-relative;

  height: 56px;
}

.table__header-row {
  height: 56px;

  th {
    border-bottom: solid 1px var(--color-border-default);
  }
}

.table__header-cell {
  @extend .left-align;

  border-radius: var(--radius-100) var(--radius-100) 0px 0px;

  white-space: nowrap;

  .button-icon {
    color: #CCCCCC;

    &::after {
      content: 'arrow_downward';
    }
    &[data-direction='ascending']::after {
      content: 'arrow_upward';
    }
    &.sorted {
      color: var(--color-icon-button);
    }
  }
}

.table__header-cell-content {
  @extend .px-3;
}

.table__row {
  height: 52px;

  &:not(:last-child) {
    td {
      border-bottom: solid 1px var(--color-border-default);
    }
  }

  &:hover {
    background-color: var(--color-table-row-hover-bg);
  }

  &:has(input:checked) {
    background-color: var(--color-table-row-selected-bg);
  }
}

.table__checkbox {
  @extend .center-align;

  min-width: 48px;
}

.table__cell {
  @extend .px-3;
  @extend .py-2;

  cursor: pointer;
}

.table__cell-content {
  &--truncated {
    white-space: nowrap;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 240px;
  }
}

.table__row-indicator-cell {
  @extend .pr-1;

  width: 4px;
}

.table__row-indicator {
  position: relative;

  &:before {
    animation-name: scale-appear;
    animation-duration: 150ms;
    background: var(--primary-color);
    border-radius: 0px var(--radius-100) var(--radius-100) 0px;
    bottom: 0;
    content: '';
    height: 32px;
    left: 0px;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 4px;
  }
}

.table__toolbar {
  background-color: var(--color-canvas-default);
  border-radius: 0 8px 0 0;
  box-sizing: border-box;
  height: 55px;
  left: 60px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: opacity 150ms ease-in-out;
  width: calc(100% - 61px);
  z-index: -1;
}
