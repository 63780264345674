.mdc-switch-deprecated {
  @include switch.toggled-on-color($han-purple);
}

.mdc-switch-ios {
  .mdc-switch__track-deprecated {
    border-radius: var(--radius-200) !important;
    height: 26px;
    width: 45px;
  }

  .mdc-switch__thumb-underlay {
    left: -11px;
    top: -11px;
  }

  .mdc-switch__thumb {
    background-color: $white !important;
    border-color: $white !important;
  }

  &.mdc-switch--checked {
    .mdc-switch__track-deprecated {
      opacity: 1;
    }

    .mdc-switch__thumb-underlay {
      transform: translateX(19px);
    }
  }

  &.mdc-switch-ios--dense {
    .mdc-switch__track-deprecated {
      border-radius: 8px !important;
      height: 16px;
      width: 32px;
    }

    .mdc-switch__thumb-underlay {
      --mdc-ripple-fg-size: 16px !important;
      --mdc-ripple-left: 16px !important;
      --mdc-ripple-top: 4px !important;
      height: 24px;
      left: -16px;
      top: -4px;
    }

    .mdc-switch__thumb {
      border: 5px solid;
      height: 10px;
      width: 10px;
    }

    &.mdc-switch--checked {
      .mdc-switch__thumb-underlay {
        transform: translateX(16px);
      }
    }

    .mdc-switch__native-control {
      height: 28px;
      width: 48px;
      left: 8px;
    }
  }
}

.woots {
  .mdc-switch-deprecated {
    @include switch.toggled-on-color($azul);
  }
}
