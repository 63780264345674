.tile {
  align-items: center;
  background-color: #FFF;
  border: 1px solid #D1D5DA;
  border-radius: var(--radius-050);
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 0 10px 10px 0;
  min-width: 60px;
}

.high-contrast {
  .tile {
    background-color: $high-contrast-background-color;
    border-color: $high-contrast-color;
  }
}
