.canvas {
  background-color: var(--color-canvas-default);
}

.canvas-inset {
  background-color: var(--color-canvas-inset);
}

.color-canvas-overlay {
  background-color: var(--color-canvas-overlay);
}

.color-canvas-subtle {
  background-color: var(--color-canvas-subtle) !important;
}
