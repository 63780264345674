.webcam-screenshot #video,
.webcam-screenshot #photo {
  height: auto;
  width: 480px;
}

.webcam-screenshot #photo {
  border: 1px solid black;
  display: none;
}

.webcam-screenshot #canvas {
  display: none;
}

.webcam-screenshot .camera,
.webcam-screenshot .output {
  display: flex;
  justify-content: center;
  width: 100%;
}

.webcam-screenshot .webcam-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.webcam-screenshot #restartbutton {
  display: none;
}

.webcam-screenshot #warning {
  color: red;
  display: none;
  text-align: center;
}
