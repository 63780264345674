.drawing {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

  .drawing-area {
    touch-action: none;

    path {
      touch-action: none;
    }
  }

  text {
    white-space: pre;
  }

  .caret-blink {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: fadeoutin;
  }
}

@keyframes fadeoutin {
    0% {
        opacity: 1
    }

    13% {
        opacity: 0
    }

    50% {
        opacity: 0
    }

    63% {
        opacity: 1
    }

    to {
        opacity: 1
    }
}

.high-contrast {
  .drawing, #circles {
    background: $high-contrast-background-color;

    [stroke="rgb(0, 0, 0)"],
    [stroke="rgb(0,0,0)"] {
      stroke: #FFFFFF !important;
    }
    [stroke="rgb(238, 0, 0)"] {
      stroke: #FF3D2A !important;
    }
    [stroke="rgb(2, 165, 86)"] {
      stroke: #02A556 !important;
    }
    [stroke="rgb(0, 0, 238)"] {
      stroke: #4A81FF !important;
    }

    [style="color: rgb(0, 0, 0);"] {
      color: #FFFFFF !important;
    }
    [style="color: rgb(238, 0, 0);"] {
      color: #FF3D2A !important;
    }
    [style="color: rgb(2, 165, 86);"] {
      color: #02A556 !important;
    }
    [style="color: rgb(0, 0, 238);"] {
      color: #4A81FF !important;
    }

    #line-width {
      background-color: $high-contrast-color !important;
    }

    .mdc-button--filled {
      background: $high-contrast-color !important;
      color: $high-contrast-background-color !important;
    }

    .border-bottom {
      border-color: $high-contrast-color !important;
    }
  }
}
