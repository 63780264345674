/* Tooltip container */
.tooltip {
  display: inline-block;
  padding: 8px 0;
  position: absolute;
  z-index: 1001;
  white-space: normal;

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: var(--color-tooltip-default-bg);
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement='top'] > .arrow {
    bottom: 4px;
  }

  &[data-popper-placement='bottom'] > .arrow {
    top: 4px;
  }

  &[data-popper-placement='left'] > .arrow {
    right: -3px;
  }

  &[data-popper-placement='right'] > .arrow {
    left: -3px;
  }
}

/* Tooltip text */
.tooltip-text {
  background-color: var(--color-tooltip-default-bg);
  border-radius: var(--radius-100);
  color: var(--color-tooltip-default-text);
  display: none;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 14px;
  max-height: 250px;
  max-width: 200px;
  padding: 8px 16px;
  text-align: center;
  width: max-content;
  -webkit-font-smoothing: initial;
  
  &.tooltip-text--wide {
    max-width: 400px;
  }
}

.remote-tooltip {
  background: $white;
  border: 1px solid $gainsboro;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px 0;
  padding: 8px;
  position: absolute;
  z-index: 100;
}

.mdc-button {
  .tooltip-text {
    text-transform: none;
  }
}

// Woots overwrites
.woots {
  .tooltip-text {
    font-family: "Montserrat", sans-serif;
  }
}
