.mdc-button--white-text {
  @include button.icon-color($white);
  @include button.ink-color($white);
  @include button.outline-color($white);
}

.mdc-button {
  @include button.ink-color($navy-blue);
  @include button.shape-radius(8px);

  letter-spacing: normal;
  padding: 0 12px;
  text-transform: none;
  --mdc-typography-button-font-family: var(--font-family-header);
  --mdc-typography-button-font-weight: var(--font-weight-header);

  .mdc-button__ripple,
  .mdc-button__label {
    pointer-events: none;
  }

  &.hover {
    pointer-events: fill;
  }
}

.mdc-button--filled {
  @extend .mdc-button--unelevated;
  @include button.container-fill-color($peach-crayola);
  @include button.horizontal-padding(16px);
  @include button.ink-color($navy-blue);
  border: 0;

  &:hover {
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
  }
}

.mdc-button--filled-secondary {
  @extend .mdc-button--filled;
  @include button.container-fill-color($han-purple);
  @include button.ink-color($white);
}

.mdc-button--tonal {
  @extend .mdc-button--unelevated;
  @include button.filled-accessible($ghost-white);
  @include button.container-fill-color($ghost-white);
  @include button.horizontal-padding(16px);
  @include button.ink-color($navy-blue);
  @include button.outline-color($ghost-white);
  border-width: 1px;

  &:hover {
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
  }
}

.mdc-button--outlined {
  @include button.ink-color($navy-blue);
  @include button.outline-color($navy-blue);
  border-width: 1px;
}

.mdc-button--outlined-secondary {
  @extend .mdc-button--outlined;
  @include button.ink-color($han-purple);
  @include button.outline-color($han-purple);
  border-width: 1px;
}

.mdc-button--danger {
  @extend .mdc-button;

  @include button.ink-color($cardinal);

  .mdc-button__ripple:before,
  .mdc-button__ripple:after {
    background-color: #CB212F !important;
  }
}

.mdc-button--dense {
  @include button.density(-2);

  .mdc-button__icon {
    margin-right: 4px;
  }

  .mdc-button__label {
    font-size: 14px;
  }
}

.mdc-button--fullwidth {
  width: -moz-available;
  width: -webkit-fill-available;
}

.mdc-button--white {
  @include button.ink-color($white);

  &.mdc-button--filled {
    @include button.ink-color($han-purple);
    @include button.container-fill-color($white);
  }

  &.mdc-button--outlined {
    @include button.outline-color($white);
  }
}

.mdc-button--condensed {
  @include button.horizontal-padding(0);
  min-width: 40px;
}

.mdc-icon-button {
  .mdc-icon-button__ripple:before,
  .mdc-icon-button__ripple:after {
    border-radius: 8px !important;
  }
  &.hover {
    pointer-events: fill;
  }
  &.mdc-icon-button--on {
    background-image: linear-gradient(rgb(0 0 0/12%) 0 0);;
    border-radius: 8px;
  }
}

// Woots overwrites
.woots {
  .mdc-button {
    @include button.shape-radius(0);
    @include button.ink-color($heading-color);
    --mdc-typography-button-font-family: 'Jost', sans-serif;
  }

  .mdc-button--filled {
    @include button.ink-color($white);
    @include button.container-fill-color($azul);
    @include button.outline-color($azul);
    border-radius: 0;
  }

  .mdc-button--tonal {
    @include button.container-fill-color(#D0DEFB);
    @include button.outline-color(#D0DEFB);
  }

  .mdc-button--outlined {
    @include button.ink-color($heading-color);
    @include button.outline-color($heading-color);
  }

  .mdc-button--danger {
    @include button.ink-color($heading-color);
    @include button.outline-color($cardinal);
  }

  .mdc-button--filled {
    @include button.ink-color($heading-color);
    @include button.container-fill-color(#61CDB0);
  }

  .mdc-button--white {
    @include button.ink-color($white);

    &.mdc-button--filled {
      @include button.ink-color($heading-color);
      @include button.container-fill-color($white);
    }

    &.mdc-button--outlined {
      @include button.outline-color($white);
    }
  }
}

.high-contrast {
  .mdc-button {
    @include button.ink-color($high-contrast-color);
  }

  .mdc-button--outlined {
    @include button.outline-color($high-contrast-color);
  }

  .mdc-button--filled {
    @include button.container-fill-color($high-contrast-background-color);
  }
}
