.btn-link {
  -moz-appearance: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  appearance: none;
  background-color: initial;
  border: 0;
  color: #0366D6;
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family-text);
  font-size: inherit;
  padding: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
}

.btn-list-item {
  -moz-appearance: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  appearance: none;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  font-family: var(--font-family-text);
  font-size: inherit;
  text-decoration: none;
  user-select: none;
  width: 100%;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-action-list-item-default-hover-bg);
  }
}

.btn-primary {
  background-color: #FCFCFC;
  border: 0;
  border-radius: var(--radius-050);
  color: #333;
  display: inline-block;
  font-family: var(--font-family-header);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 3px 6px;

  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));

  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
  }

  &:active {
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  }
}

.btn-secondary {
  background-color: $peach-crayola;
  border: 1px solid $peach-crayola;
  border-radius: var(--radius-050);
  color: $navy-blue;
  font-family: var(--font-family-header);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 3px 6px;

  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));

  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
  }

  &:active {
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  }
}

.btn-tertiary {
  background-color: $davys-grey;
  border: 1px solid $davys-grey;
  border-radius: var(--radius-050);
  color: $white;
  font-size: 12px;
  line-height: 20px;
  padding: 3px 6px;

  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));

  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
  }

  &:active {
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  }
}

.btn-import {
  border: 1px solid rgba(27,31,35,.2);
  border-radius: var(--radius-050);
  font-size: 12px;
  line-height: 20px;
  padding: 3px 6px;

  &:hover {
    background-color: #0366D6;
    border-color: #0366D6;
    color: #fff;
  }
}

.app-fab--absolute {
  bottom: 16px;
  display: flex;
  position: fixed;
  right: 16px;
}

// Woots
.woots-button {
  align-items: center;
  border: 0;
  border-radius: 20px;
  color: $white;
  display: inline-flex;
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 16px;
  --mdc-typography-font-family: 'Jost', sans-serif;

  &.woots-button--azul {
    background-color: $azul;

    &:hover {
      background-color: $pastel-blue;
    }
  }

  &.woots-button--maize {
    background-color: $maize;
    color: $black;
  }

  &.woots-button--grapefruit {
    background-color: $grapefruit;

    &:hover {
      background-color: $grapefruit-hover;
    }
  }

  &.woots-button--green-blue {
    background-color: $green-blue;

    &:hover {
      background-color: $green-blue-hover;
    }
  }

  &.woots-button--pastel-blue {
    background-color: $pastel-blue;

    &:hover {
      background-color: $azul;
    }
  }
}

// WOOTS OVERWRITES

.woots {
  .btn-secondary {
    background-color: #61CDB0;
    border: 1px solid #61CDB0;
    border-radius: 0;
  }

  .btn-tertiary {
    border-radius: 0;
  }
}

.high-contrast {
  .btn-primary {
    background-color: $high-contrast-color;
    border-color: $high-contrast-color;
    color: $high-contrast-background-color;
  }
}
