.banner {
  align-items: start;
  background-color: var(--color-banner-secondary-bg);
  border: 1px solid var(--color-banner-secondary-border);
  border-radius: var(--radius-100);
  column-gap: 16px;
  display: flex;
  padding: 16px;

  .banner__icon {
    color: var(--color-banner-secondary-border);
    font-size: 24px;
  }

  .banner__title {
    font-size: 16px;
  }

  .banner__description {
    font-size: 14px;
  }
}

@each $type in ("banner-danger", "banner-info", "banner-success", "banner-warning") {
  .#{$type} {
    @extend .banner;

    background: var(--color-#{$type}-bg);
    border-color: var(--color-#{$type}-border);

    .banner__icon {
      color: var(--color-#{$type}-icon);
    }
  }
}

.box-with-banner {
  .banner {
    align-items: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 8px;
  }
}
