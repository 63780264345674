.answer-field {
  .mdc-text-field {
    @include textfield.outlined-height(40px);
    width: 100%;
  }

  .answer-position-wrapper {
    width: 100px;
  }
}
