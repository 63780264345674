.accessibility-warning {
  display: none;
}

.show-accessibility-issues {
  .accessibility-error {
    border: 2px solid $cardinal;
  }

  .accessibility-warning {
    color: $cardinal;
    display: inline-block;
    margin: 0 2px;
  }
}
