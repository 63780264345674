pie-chart {
    vertical-align: bottom;
    display: inline-block;
    block-size: 14px;
    margin: 0 0.5px;
}

pie-chart circle.pie {
    fill: rgba(0,0,0,0);
    stroke: var(--color-secondary-400);
    opacity: .5
}

pie-chart circle.slice {
    fill: rgba(0,0,0,0);
    stroke: var(--color-secondary-400)
}

pie-chart svg {
    border-radius: 50%;
    transform: rotate(-90deg)
}
