.box {
  background-color: var(--color-canvas-default);
  border: 1px solid var(--color-border-default);
  border-radius: var(--radius-100);

  &.box--code {
    font-size: 12px;
    padding: 4px;
  }

  &.box--filled {
    background-color: #F6F8FA;
    border-color: #F6F8FA;
  }

  &--clickable {
    &:hover {
      background-color: var(--color-box--clickable-hover-bg);
      cursor: pointer;
    }

    &:active {
      background-color: var(--color-box--clickable-active-bg);
    }
  }
}

.box-filled {
  background-color: var(--color-canvas-subtle);
  border-top-left-radius: var(--radius-100);
  border-top-right-radius: var(--radius-100);
}

.box-heading {
  background-color: var(--color-canvas-subtle);
  border-bottom: 1px solid var(--color-border-default);
  border-top-left-radius: var(--radius-100);
  border-top-right-radius: var(--radius-100);
  color: $heading-color;
  font-family: var(--font-family-header);
  font-weight: 500;
}

.box-row {
  border-top: 1px solid var(--color-border-default);
  list-style-type: none;
  margin-top: -1px;

  &:first-child {
    border-radius: var(--radius-100) var(--radius-100) 0 0;
    border: 0;
  }

  &:last-child {
    border-bottom-left-radius: var(--radius-100);
    border-bottom-right-radius: var(--radius-100);
  }

  &--clickable {
    &:hover {
      background-color: var(--color-box--clickable-hover-bg);
      cursor: pointer;
    }

    &:active {
      background-color: var(--color-box--clickable-active-bg);
    }
  }

  &--hoverable {
    &:hover {
      background-color: var(--color-box--clickable-hover-bg);
    }
  }
}

.box-divider {
  background-color: $alabaster;
  border-top: 1px solid #E1E4E8;
  list-style-type: none;
  text-align: center;


  &:first-child {
    border: 0;
    border-top-left-radius: var(--radius-100);
    border-top-right-radius: var(--radius-100);
  }

  &:last-child {
    border-bottom-left-radius: var(--radius-100);
    border-bottom-right-radius: var(--radius-100);
  }
}

.box--danger {
  border-color: var(--color-danger-600);
}

.box--warning {
  background-color: #FFF9E4;
  border: 2px solid #FFB703;
  padding: 8px;

  .material-icons {
    color: #FFB703;
  }
}

.box--active {
  background: #FCFCFF;
  border-color: $navy-blue !important;
  color: $navy-blue !important;
  font-weight: 500;
}

.box--highlight {
  border-color: $atomic-tangerine;
  border-width: 2px;
  color: $black;
}

.high-contrast {
  .box {
    border-color: $high-contrast-color;
  }
}

.box-with-banner {
  .box {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
