.x-spreadsheet-bottombar {
  display: none;
}

.x-spreadsheet-toolbar-btn {
  &[data-tooltip="Print (Ctrl+P)"] {
    display: none;
  }
}

.x-spreadsheet-item {
  .label {
    background-color: initial;
    border: none;
    border-radius: 0;
    color: initial;
    display: initial;
    font-size: inherit;
    padding: 0;
    vertical-align: initial;
  }
}
