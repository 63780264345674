.subquestion {
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: var(--radius-050);
  box-shadow: 0 1px 1px rgba(27,31,35,.1);
  page-break-inside: avoid;
}

.subquestion-selector {
  background-color: transparent;
  display: flex;
  flex: 0 1 auto;
  margin: 12px 0;
  overflow-x: auto;
  position: relative;
}

.subquestion-selector-item {
  -webkit-flex: 0 1 auto;
  background-color: initial;
  border: 2px solid $gainsboro;
  border-bottom: 4px solid $gainsboro;
  border-radius: var(--radius-050);
  box-sizing: border-box;
  color: $shuttle-gray;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  line-height: 24px;
  margin: 0 2px;
  min-width: 40px;
  padding: 8px 4px 6px 4px;
  position: relative;
  text-align: left;

  &.active {
    background-color: $shark;
    border-color: $shark;
    color: $white;
  }

  &:not(.active):hover {
    background-color: $ghost-white;
  }

  &.subquestion-selector-item--correct {
    border-bottom-color: $turquoise-green;
  }

  &.subquestion-selector-item--mistake {
    border-bottom-color: $jasmine;
  }

  &.subquestion-selector-item--incorrect {
    border-bottom-color: $light-coral;
  }

  &.subquestion-selector-item--answered {
    background-color: #FFD9C4;
  }

  &.subquestion-selector-item--current {
    background-color: $han-purple;
    border-left-color: $han-purple;
    border-right-color: $han-purple;
    border-top-color: $han-purple;
    color: $white;
    pointer-events: none;

    &:hover {
      background-color: $han-purple;
    }
  }

  &.subquestion-selector-item--locked {
    -webkit-font-smoothing: unset;
    color: $pale-sky;
    pointer-events: none;
    visibility: hidden;

    &:before {
      background-color: #E1E4E8;
      border-radius: var(--radius-050);
      box-sizing: border-box;
      content: "";
      height: 40px;
      min-width: 40px;
      position: absolute;
      top: 0;
      visibility: visible;
    }

    &:after {
      -moz-font-feature-settings: 'liga' 1;
      -webkit-font-feature-settings: 'liga' 1;
      content:'lock';
      display: block;
      font-family: 'Material Icons Outlined';
      font-feature-settings: 'liga' 1;
      font-size: 24px;
      padding: 5px 0;
      position: absolute;
      top: 2px;
      visibility: visible;
    }

    &:hover {
      background-color: $gainsboro;
    }
  }
}

.sortable-ghost {
  pointer-events: none;
}

.question-group {
  background-color: var(--color-canvas-overlay);
  border-radius: var(--radius-100);
}

.grouped-subquestions {
  .numeral {
    background-color: $ghost-white;
    padding: 0 8px 8px 8px;
    pointer-events: none;
  }

  .subquestion-details {
    padding-bottom: 8px;
  }

  &:first-child {
    .numeral {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding-top: 8px !important;
    }

    .subquestion-details {
      padding-bottom: 0 !important;
    }
  }

  &:last-child {
    .numeral {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.woots {
  .subquestion {
    border: 0;
    border-radius: 0;
  }

  .subquestion-selector-item {
    border-radius: 0;
  }
}

.high-contrast {
  .subquestion-selector-item {
    border-color: $high-contrast-color;
    color: $eerie-black;

    &:not(.subquestion-selector-item--locked) {
      color: $high-contrast-color;
    }

    &:not(.active) {
      &:hover {
        background-color: $high-contrast-background-color;
        border-color: $high-contrast-color;
        color: $high-contrast-color;
      }
    }

    &.subquestion-selector-item--answered {
      background-color: rgba(255, 222, 65, .7);
      border-color: transparent;
      color: $high-contrast-background-color;
    }

    &.subquestion-selector-item--current {
      background-color: $high-contrast-color !important;
      color: $high-contrast-background-color;
    }
  }

  .question-group {
    background-color: $gunmetal;
  }

  .numeral {
    background-color: $eerie-black;
  }
}
