.kprime-table {
  border-spacing: 0;

  .kprime-column {
    max-width: 300px;
    width: max-content;
  }

  .kprime-row {
    td {
      border-bottom: 1px solid lightgray;
    }

    &:last-child > td {
      border-bottom: none;
    }
  }

  .kprime-text {
    width: 100%;
  }
}

.high-contrast {
  .kprime-table {
    .kprime-row {
      td {
        border-bottom: 1px solid $high-contrast-color;
      }
    }
  }
}
