@each $key, $value in $units {
  .gap-#{$key} {
    gap: $value !important;
  }

  .column-gap-#{$key} {
    column-gap: $value !important;
  }

  .row-gap-#{$key} {
    row-gap: $value !important;
  }
}
