:root {
  --radius-050: 4px;
  --radius-100: 8px;
  --radius-200: 16px;

  &.woots {
    --radius-050: 0;
    --radius-100: 0;
  }
}
