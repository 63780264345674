.mdc-drawer {
  max-width: 500px;
  width: 25%;

  &.mdc-drawer--open {
    &:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
      margin-left: 0;
      margin-right: auto;
      min-width: calc(100% - 500px);
      width: 75%;
    }
  }

  .group {
    background-color: $ghost-white;
  }

  &.mdc-drawer--fullwidth {
    max-width: unset;
    width: 100%;
  }
}

.mdc-drawer-scrim {
  background-color: rgba(#000, 0.6) !important;
}

.mdc-drawer-app-content {
  width: 100%;
}

.high-contrast {
  .mdc-drawer {
    background-color: $high-contrast-background-color;
    color: $high-contrast-color;

    .group {
      background-color: $gunmetal;
    }
  }
}
