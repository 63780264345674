.redactor-equation .tabs {
  margin-bottom: 15px;
}

.redactor-equation .tile {
  background-color: #EEE;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  margin-bottom: 9px;
  margin-right: 9px;
  overflow: hidden;
  text-align: center;
  width: 45px;
}

.redactor-equation .katex {
  font-size: 1.1em;
}

.ML__popover {
  z-index: 1052 !important;
}
