.option-list-field {
  .mdc-text-field {
    @include textfield.outlined-height(40px);
    width: 100px;
  }

  .option-list-content-wrapper {
    flex-grow: 1;

    .mdc-text-field {
      width: 100%;
    }
  }
}

.redactor-component {
  &[data-redactor-type='optionList'] {
    @extend .chip;

    text-transform: unset;
  }
}
