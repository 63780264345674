.color-fg-muted {
  color: var(--color-fg-muted);
}

.color-fg-subtle {
  color: var(--color-fg-subtle);
}

.muted-link {
  color: var(--color-fg-muted) !important;

  &:hover {
    color: var(--color-accent-fg) !important;
  }
}

// DEPRECATING BELOW

.desaturate {
  &:not(:hover) {
    filter: grayscale(100%) !important;
  }
}

.jade {
  color: $jade !important;
}


.link-gray-dark {
  color: $shark !important;

  &:hover {
    color: $celtic-blue !important;
  }
}

.salomie {
  color: #ffe082 !important;
}

.white {
  color: $white !important;
}

.black {
  color: $black !important;
}

.dove-gray {
  color: $dove-gray !important;
}

.b-dove-gray {
  background-color: $dove-gray !important;
}

.gainsboro {
  color: $gainsboro !important;
}

.b-gainsboro {
  background-color: $gainsboro !important;
}

.dusty-gray {
  color: #949494 !important;
}

.charleston-green {
  color: $charleston-green !important;
}

.cardinal {
  color: #CB212F !important;
}

.b-cardinal {
  background-color: #CB212F !important;
}

.han-purple {
  color: $han-purple !important;
}

.b-han-purple {
  background-color: $han-purple !important;
}

.pastel-blue {
  color: $pastel-blue !important;
}

.navy-blue {
  color: $navy-blue !important;
}

.b-navy-blue {
  background-color: $navy-blue !important;
}

.b-alabaster {
  background-color: $alabaster !important;
}

.b-proctorio {
  background-color: $proctorio !important;
}

.ghost-white {
  color: $ghost-white !important;
}

.b-ghost-white {
  background-color: $ghost-white !important;
}

.b-white {
  background-color: $white !important;
}

.b-black {
  background-color: $black !important;
}

.b-cultured {
  background-color: $cultured !important;
}

.jasmine {
  color: $jasmine !important;
}

.b-jasmine {
  background-color: $jasmine !important;
}

.light-coral {
  color: $light-coral !important;
}

.b-light-coral {
  background-color: $light-coral !important;
}

.turquoise-green {
  color: $turquoise-green !important;
}

.b-turquoise-green {
  background-color: $turquoise-green !important;
}

.atomic-tangerine {
  color: $atomic-tangerine !important;
}

.mountain-meadow {
  color: $mountain-meadow !important;
}

.green-crayola {
  color: $green-crayola !important;
}

.b-green-crayola {
  background-color: $green-crayola !important;
}

.green-pigment {
  color: #00A349 !important;
}

.mikado-yellow {
  color: $mikado-yellow !important;
}

.b-mikado-yellow {
  background-color: $mikado-yellow !important;
}

.platinum {
  color: $platinum !important;
}

.b-platinum {
  background-color: $platinum !important;
}

.b-lavender-blue {
  background-color: $lavender-blue !important;
}

.forest-green-web {
  color: $forest-green-web !important;
}

.tart-orange {
  color: $tart-orange !important;
}

.b-tart-orange {
  background-color: $tart-orange !important;
}

.light-salmon {
  color: $light-salmon !important;
}

.b-light-salmon {
   background-color: $light-salmon !important;
}

.maximum-yellow-red {
  color: $maximum-yellow-red !important;
}

.heading-color {
  color: $heading-color !important;
}

.text-color {
  color: $text-color !important;
}

.background-color {
  background-color: #F7F7F7 !important;
}

.b-background-color {
  background-color: var(--color-bg-default);
}

.b-dark-mode {
  background-color: #121212;
}

.b-intensity-1 {
  background-color: rgba(255, 255, 255, .05);
}

.woots {
  .background-color {
    background-color: $very-light-pink !important;
  }

  .image-placeholder-background-color {
    background-color: #F5F5F5;
  }
}

// Woots
.azul {
  color: $azul !important;
}

.grapefruit {
  color: $grapefruit !important;
}

.b-azul {
  background-color: $azul !important;
}

.b-pale-2 {
  background-color: $pale-2 !important;
}

.b-parchment {
  background-color: $parchment !important;
}

.b-very-light-pink {
  background-color: $very-light-pink !important;
}
