@each $key, $value in $units {
  .m-#{$key} {
    margin: $value !important;
  }

  .mb-#{$key}, .my-#{$key} {
    margin-bottom: $value !important;
  }

  .ml-#{$key}, .mx-#{$key} {
    margin-left: $value !important;
  }

  .mr-#{$key}, .mx-#{$key} {
    margin-right: $value !important;
  }

  .mt-#{$key}, .my-#{$key} {
    margin-top: $value !important;
  }
}

.mt-n1 {
  margin-top: -4px !important;
}

.mr-n1 {
  margin-right: -4px !important;
}

.mb-n1 {
  margin-bottom: -4px !important;
}

.ml-n1 {
  margin-left: -4px !important;
}

.mt-n2 {
  margin-top: -8px !important;
}

.mr-n2 {
  margin-right: -8px !important;
}

.mb-n2 {
  margin-bottom: -8px !important;
}

.ml-n2 {
  margin-left: -8px !important;
}

.mt-n3 {
  margin-top: -16px !important;
}

.mr-n3 {
  margin-right: -16px !important;
}

.mb-n3 {
  margin-bottom: -16px !important;
}

.ml-n3 {
  margin-left: -16px !important;
}

.mt-n4 {
  margin-top: -24px !important;
}

.mr-n4 {
  margin-right: -24px !important;
}

.mb-n4 {
  margin-bottom: -24px !important;
}

.ml-n4 {
  margin-left: -24px !important;
}

.mt-n5 {
  margin-top: -32px !important;
}

.mr-n5 {
  margin-right: -32px !important;
}

.mb-n5 {
  margin-bottom: -32px !important;
}

.ml-n5 {
  margin-left: -32px !important;
}

.mt-n6 {
  margin-top: -40px !important;
}

.mr-n6 {
  margin-right: -40px !important;
}

.mb-n6 {
  margin-bottom: -40px !important;
}

.ml-n6 {
  margin-left: -40px !important;
}

.mx-n6 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.mx-n8 {
  margin-left: -72px !important;
  margin-right: -72px !important;
}

@media (max-width: 599px) {
  .phone-mr-3 {
    margin-right: 16px !important;
  }
}
