:root[data-color-mode="dark"] {
  --color-accent-fg: var(--color-info-500);
  --color-bg-default: var(--color-bg-950);
  --color-banner-danger-bg: var(--color-danger-950);
  --color-banner-danger-border: var(--color-danger-600);
  --color-banner-danger-icon: var(--color-danger-600);
  --color-banner-default-text: var(--color-text-100);
  --color-banner-info-bg: var(--color-info-950);
  --color-banner-info-border: var(--color-info-600);
  --color-banner-info-icon: var(--color-info-600);
  --color-banner-secondary-bg: var(--color-secondary-950);
  --color-banner-secondary-border: var(--color-secondary-600);
  --color-banner-secondary-icon: var(--color-secondary-600);
  --color-banner-success-bg: var(--color-success-950);
  --color-banner-success-border: var(--color-success-600);
  --color-banner-success-icon: var(--color-success-600);
  --color-banner-warning-bg: var(--color-warning-950);
  --color-banner-warning-border: var(--color-warning-600);
  --color-banner-warning-icon: var(--color-warning-600);
  --color-box--clickable-active-bg: var(--color-bg-800);
  --color-box--clickable-hover-bg: var(--color-bg-850);
  --color-button-nav-bg-active: var(--color-secondary-200);
  --color-button-nav-bg-hover: var(--color-secondary-300);
  --color-button-nav-bg: var(--color-secondary-400);
  --color-button-nav-text: var(--color-text-950);
  --color-button-primary-bg-active: var(--color-primary-300);
  --color-button-primary-bg-disabled: var(--color-primary-800);
  --color-button-primary-bg-hover: var(--color-primary-400);
  --color-button-primary-bg: var(--color-primary-500);
  --color-button-primary-text-disabled: var(--color-primary-300);
  --color-button-primary-text: var(--color-text-950);
  --color-button-secondary-bg-active: var(--color-secondary-900);
  --color-button-secondary-bg-hover: var(--color-secondary-950);
  --color-button-secondary-border: var(--color-secondary-300);
  --color-button-secondary-text: var(--color-secondary-300);
  --color-button-tertiary-bg-active: var(--color-secondary-900);
  --color-button-tertiary-bg-hover: var(--color-secondary-950);
  --color-button-tertiary-text: var(--color-secondary-300);
  --color-checkbox-bg: var(--color-secondary-300);
  --color-checkbox-border: var(--color-text-100);
  --color-checkbox-mark: var(--color-text-950);
  --color-chip-danger-bg: var(--color-danger-950);
  --color-chip-danger-border: var(--color-danger-600);
  --color-chip-default-bg: var(--color-secondary-950);
  --color-chip-default-border: var(--color-secondary-600);
  --color-chip-default-icon: var(--color-text-100);
  --color-chip-default-text: var(--color-text-100);
  --color-chip-info-bg: var(--color-info-950);
  --color-chip-info-border: var(--color-info-600);
  --color-chip-progress-bg: var(--color-bg-900);
  --color-chip-progress-border: var(--color-bg-800);
  --color-chip-success-bg: var(--color-success-950);
  --color-chip-success-border: var(--color-success-600);
  --color-chip-unknown-bg:  var(--color-bg-900);
  --color-chip-unknown-border: var(--color-bg-800);
  --color-chip-unknown-text: var(--color-text-200);
  --color-chip-warning-bg: var(--color-warning-950);
  --color-chip-warning-border: var(--color-warning-600);
  --color-filter-button-active-border: var(--color-secondary-500);
  --color-filter-button-bg: var(--color-bg-950);
  --color-filter-button-border: var(--color-bg-800);
  --color-filter-button-hover-bg: var(--color-bg-900);
  --color-filter-button-selected-bg: var(--color-secondary-900);
  --color-filter-button-selected-border: var(--color-secondary-900);
  --color-filter-button-text: var(--color-text-100);
  --color-filter-item-hover-bg: var(--color-bg-900);
  --color-filter-item-selected-bg: var(--color-secondary-900);
  --color-filter-item-text: var(--color-text-100);
  --color-highlight-color-yellow: var(--color-warning-800);
  --color-highlight-color-blue: var(--color-info-800);
  --color-highlight-color-green: var(--color-success-800);
  --color-highlight-color-purple: var(--color-secondary-800);
  --color-highlight-color-red: var(--color-danger-800);
  --color-highlight-hover-bg: var(--color-bg-850);
  --color-icon-success: var(--color-success-400);
  --color-icon-filled-success: var(--color-bg-050);
  --color-icon-filled-success-bg: var(--color-success-400);
  --color-light-up-bg: var(--color-warning-900);
  --color-list-item-hover-bg: var(--color-bg-900);
  --color-list-item-selected-bg: var(--color-secondary-900);
  --color-list-item-text: var(--color-text-050);
  --color-mark-bg: var(--color-secondary-900);
  --color-nav-search-bar-bg: var(--color-secondary-300);
  --color-nav-search-bar-placeholder: var(--color-text-800);
  --color-nav-search-bar-text-selected: var(--color-text-950);
  --color-nav-search-bar-text: var(--color-text-800);
  --color-progress-bar-finished-bg: var(--color-secondary-300);
  --color-progress-bar-started-bg: var(--color-secondary-600);
  --color-progress-bar-unstarted-bg: var(--color-bg-800);
  --color-question-button-danger-bg-hover: var(--color-danger-950);
  --color-question-button-danger-bg-selected: var(--color-danger-900);
  --color-question-button-danger-border: var(--color-danger-600);
  --color-question-button-default-bg-hover: var(--color-secondary-850);
  --color-question-button-default-bg-selected: var(--color-secondary-900);
  --color-question-button-default-bg: var(--color-bg-950);
  --color-question-button-default-border: var(--color-secondary-300);
  --color-question-button-default-text: var(--color-text-050);
  --color-question-button-icon-bg: var(--color-secondary-300);
  --color-question-button-icon-text: var(--color-bg-950);
  --color-question-button-indicator-bg: var(--color-secondary-300);
  --color-question-button-info-bg-hover: var(--color-info-950);
  --color-question-button-info-bg-selected: var(--color-info-900);
  --color-question-button-info-border: var(--color-info-600);
  --color-question-button-success-bg-hover: var(--color-success-950);
  --color-question-button-success-bg-selected: var(--color-success-600);
  --color-question-button-success-border: var(--color-success-600);
  --color-question-button-warning-bg-hover: var(--color-warning-950);
  --color-question-button-warning-bg-selected: var(--color-warning-900);
  --color-question-button-warning-border: var(--color-warning-600);
  --color-redactor-active-border: var(--color-secondary-300);
  --color-redactor-bg: var(--color-bg-950);
  --color-redactor-border: var(--color-bg-800);
  --color-redactor-dialog-trigger-active-bg: var(--color-bg-800);
  --color-redactor-dialog-trigger-hover-bg: var(--color-bg-850);
  --color-redactor-hover-bg: var(--color-bg-850);
  --color-redactor-icon: var(--color-text-200);
  --color-right-panel-bg: var(--color-bg-050);
  --color-search-bar-bg: var(--color-bg-900);
  --color-search-bar-placeholder: var(--color-text-200);
  --color-search-bar-text-selected: var(--color-text-050);
  --color-search-bar-text: var(--color-text-100);
  --color-side-panel-bg: var(--color-bg-950);
  --color-snackbar-bg: var(--color-text-800);
  --color-snackbar-button: var(--color-secondary-300);
  --color-snackbar-error-bg: var(--color-danger-900);
  --color-snackbar-text: var(--color-bg-050);
  --color-split-screen-handle-bg: var(--color-bg-900);
  --color-split-screen-handle-drag-bg: var(--color-bg-700);
  --color-split-screen-button-bg: var(--color-bg-950);
  --color-split-screen-button-active: var(--color-secondary-900);
  --color-split-screen-button-icon-bg: var(--color-secondary-300);
  --color-split-screen-button-border: var(--color-border-default);
  --color-text-danger-text: var(--color-danger-600);
  --color-table-row-hover-bg: var(--color-bg-850);
  --color-table-row-selected-bg: var(--color-secondary-950);
  --color-text-default-text: var(--color-text-050);
  --color-text-success-text: var(--color-success-700);
  --color-text-support-text: var(--color-text-300);
  --color-tooltip-default-bg: var(--color-text-050);
  --color-tooltip-default-text: var(--color-text-950);
}
