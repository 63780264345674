.match-table {
  overflow-x: auto;

  .column-header {
    min-width: 73px;
  }

  .row-name {
    line-height: 1.4em;
  }

  .mdc-text-field__input {
    min-width: 20px;
  }

  .redactor-content {
    margin: 0;
  }
}

.response-match-matrix {
  .column-header {
    min-width: 44px;
  }
}
