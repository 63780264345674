.mdc-deprecated-list {
  background-color: var(--color-canvas-overlay);
  padding: 0;

  .mdc-form-field {
    label {
      pointer-events: none;
    }
  }
}

.mdc-deprecated-list-item--focused {
  background-color: #E8E8E8;
}

a, label {
  &.mdc-deprecated-list-item {
    color: var(--color-fg-default);

    &:focus, &:hover {
      background-color: var(--color-action-list-item-default-hover-bg);
    }
  }
}

.mdc-deprecated-list-item--disabled {
  pointer-events: none;
}

.hover {
  pointer-events: all;
}

[data-js-filter],
.remote-filter {
  .mdc-menu {
    padding: 16px;

    &.mdc-menu-surface {
      border-radius: var(--radius-100);
      border: 1px solid #E8E8E8;
      box-shadow: 0px 4px 8px 0px rgba(36, 41, 47, 0.05);
    }
  }
}

.mdc-menu {
  .mdc-deprecated-list-item__graphic {
    color: var(--color-fg-muted);
  }

  .mdc-list {
    padding: 16px;

    .mdc-list-item {
      border-radius: 6px;
      cursor: pointer;
      padding: 8px 16px;
      white-space: nowrap;

      &.mdc-list-item--danger {
        color: var(--color-danger-600);
      }

      &:not(&:last-child) {
        margin-bottom: 4px;
      }

      &:focus, &:hover {
        background-color: var(--color-filter-item-hover-bg);
      }
    }
  }
}

.high-contrast {
  .mdc-menu {
    background-color: $high-contrast-background-color;

    .mdc-deprecated-list {
      background-color: $high-contrast-background-color;
    }
  }

  .mdc-deprecated-list-item {
    &:focus, &:hover {
      background-color: rgba($high-contrast-color, .08);
    }

    .mdc-deprecated-list-item__text {
      color: $high-contrast-color;
    }
  }

  .mdc-deprecated-list-item__ripple {
    &::before {
      background-color: $white !important;
    }
  }
}
