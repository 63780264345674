.pages {
  .page {
    &[data-page-id=""] {
      border-color: var(--color-secondary-700) !important;
    }
    &[data-page-id]:not([data-page-id=""]) {
      .suggestion {
        .button-icon {
          @extend .material-icons-filled;
          @extend .button-icon--toggled;
        }
      }
    }

    .suggestion {
      color: var(--color-secondary-700);
      h5 {
        color: var(--color-secondary-700);
      }
    }

    .image {
      position: relative;

      .buttons {
        position: absolute;
        right: 8px;
        top: 8px;
      }

      img + .buttons {
        .buttons-box {
          display: none;
        }
      }
    }
  }
}
