.split-screen {
  display: flex;
  height: calc(100vh - 64px);

  .inline-panel {
    min-width: 0px;
    transition: min-width 300ms ease-in-out;
    width: 0px;
  }

  .handle {
    align-items: center;
    background-color: var(--color-split-screen-handle-bg);
    cursor: ew-resize;
    display: grid;
    height: calc(100vh - 64px);
    justify-content: center;
    position: sticky;
    top: 0;
    touch-action: none;
    width: 12px;
    z-index: 3;

    .drag {
      background-color: var(--color-bg-300);
      border-radius: var(--radius-200);
      height: 80px;
      width: 4px;
      align-self: auto;
      justify-self: center;
    }

    .handle-button {
      align-items: center;
      align-self: start;
      background: var(--color-split-screen-button-bg);
      border-radius: 360px;
      border: 1px solid var(--color-split-screen-button-border);
      box-shadow: 0px 4px 8px 0px rgba(36, 41, 47, 0.05);
      cursor: pointer;
      display: flex;
      height: 56px;
      justify-content: center;
      margin-top: 72px;
      left: -22px;
      position: absolute;
      width: 56px;

      &.handle-button-active {
        background: var(--color-split-screen-button-active);
      }

      .material-icons {
        color: var(--color-split-screen-button-icon-bg);
      }
    }
  }
}

.left-panel {
  box-sizing: border-box;
  flex: 0 0 auto;
  position: relative;
  width: 60vw;

  &.left-panel--50vw {
    width: 50vw;
  }

  .mdc-tab-bar {
    border-bottom: 1px solid var(--color-bg-500);
  }
}

.right-panel {
  background-color: var(--color-right-panel-bg);
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
  position: relative;
}
