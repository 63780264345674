.mdc-select {
  @include select.bottom-line-color((focus: $han-purple));
  @include select.dropdown-icon-color((focus: $han-purple));
  @include select.label-color((focus: $han-purple));
  @include select.variable-width(150px);

  &.mdc-select--dense {
    @include select.filled-density(-2);
    @include select.filled-height(40px);
  }
}

.mdc-select--outlined {
  @include select.outline-color((default: #D1D1D1, hover: #A2A2A2));

  .mdc-select__anchor {
    height: 40px;

    .mdc-select__selected-text:empty {
      &:before {
        content: attr(placeholder);
        color: var(--color-text-support-text);
      }
    }
  }

  .mdc-select__menu {
    border: 1px solid #D1D1D1;
    border-radius: var(--radius-100);
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }

  .mdc-list-item {
    border-radius: 6px;
    margin: 4px 0;
    padding: 8px 16px;

    .mdc-checkbox,
    label {
      pointer-events: none;
    }

    &:has(.mdc-checkbox__native-control:checked) {
      @extend .mdc-list-item--selected;
    }

    .mdc-list-item__text {
      &:before {
        content: attr(placeholder);
        color: var(--color-text-support-text);
      }
    }
  }
}

.mdc-floating-label--float-above {
  min-width: fit-content;
}

.woots {
  .mdc-select {
    @include select.bottom-line-color((focus: $azul));
    @include select.dropdown-icon-color((focus: $azul));
    @include select.label-color((focus: $azul));
  }
}

.high-contrast {
  .mdc-select {
    @include select.bottom-line-color((focus: $high-contrast-color));
    @include select.container-fill-color((default: $high-contrast-background-color));
    @include select.label-color((focus: $high-contrast-color));
    @include select.outline-color((default: $high-contrast-color, hover: $high-contrast-color, focus: $high-contrast-color));
    @include select.ink-color((default: $high-contrast-color));
    @include select.dropdown-icon-color((default: $high-contrast-color, hover: $high-contrast-color, focus: $high-contrast-color));
    @include select.variable-width(150px);
  }
}
