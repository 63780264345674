.criterium {
  max-width: 1000px;
}

.criterium-selector {
  border-radius: var(--radius-050);
  color: $pale-sky;

  &.criterium-selector--toggling {
    opacity: .5;
    pointer-events: none;
  }

  &.criterium-selector--selected {
    color: $text-color;

    &.criterium-selector--correct {
      background-color: rgba(0, 140, 96, .2);

      .criterium-toggle {
        background-color: $green-crayola;
      }
    }

    &.criterium-selector--incorrect {
      background-color: rgba(179, 3, 0, .2);

      .criterium-toggle {
        background-color: $cardinal;
      }
    }

    &.criterium-selector--none {
      background-color: rgba(69, 69, 69, .2);

      .criterium-toggle {
        background-color: $shark;
      }
    }

    .criterium-toggle {
      border-bottom: 0;
      color: $white;
    }
  }

  .comment {
    background-color: rgba($white, .6);
  }
}

.criteria--rubrics {
  .criterium-selector--selected {
    .criterium-toggle {
      pointer-events: none;
    }
  }
}

.criterium-toggle {
  background-color: #EAECEF;
  border-bottom: 2px solid $pale-sky;
  border-radius: var(--radius-050);
  color: $text-color;
  display: inline-block;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  min-width: 24px;
  padding: 4px;
  text-align: center;

  &:active {
    border-bottom: 1px solid $pale-sky;
  }

  &.disabled {
    pointer-events: none;
  }

  &.criterium-toggle--condensed {
    border-width: 1px;
    height: 18px;
    line-height: 18px;
    min-width: 18px;
  }
}

.woots {
  .criterium-toggle,
  .criterium-selector {
    border-radius: 0;
  }
}
