html:root {
  --font-family-text: Nunito, sans-serif;
  --font-family-header: Poppins, sans-serif;
  --font-weight-header: 500;
  --background-color: #FFFFFF;
  --primary-color: var(--color-secondary-700);

  &.dyslexia {
    --font-family-text: Arial, sans-serif;
    --font-family-header: Helvetica, sans-serif;
    --font-weight-header: 600;
    --color-bg-default: #F2F1F8;

    em {
      font-style: normal;
    }
  }

  &.high-contrast {
    --color-bg-default: #212121;
  }

  &.woots {
    --primary-color: #2664EF;
    --font-family-text: Montserrat, sans-serif;
    --font-family-header: Jost, sans-serif;
  }
}


