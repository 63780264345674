.separator {
  align-items: center;
  display: flex;
  gap: 16px;

  &:empty {
    gap: 0;
  }

  &:before,
  &:after {
    background: var(--color-border-default);
    content:" ";
    flex-grow: 1;
    height: 1px;
  }
}
