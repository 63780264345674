.tag {
  background-color: #F3EDF8;
  border: 1px solid #F3EDF8;
  border-radius: var(--radius-050);
  color: $text-color;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  margin-right: 4px;
  margin-top: 2px;
  padding: 12px 12px 0 12px;
  vertical-align: middle;

  .tag-content {
    max-width: 150px;
    overflow-x: auto;
    padding-bottom: 12px;
  }
}

.woots {
  .tag {
    background-color: #E0E9FD;
    border: 1px solid #E0E9FD;
    border-radius: 0;
  }
}
