.snackbar {
  @extend .f4;
  background-color: var(--color-snackbar-bg, #24292F);
  border-radius: var(--radius-100, 8px);
  bottom: 24px;
  color: var(--color-snackbar-text, #FFF);
  font-weight: 400;
  left: 24px;
  padding: 16px 24px;
  position: fixed;
  z-index: 1002;

  a {
    color: var(--color-snackbar-button);
  }
}

.snackbar--error {
  background-color: var(--color-snackbar-error-bg);
}
