.search-bar {
  align-items: center;
  background-color: var(--color-search-bar-bg);
  border-radius: var(--radius-100, 8px);
  color: var(--color-search-bar-text);
  cursor: text;
  display: flex;
  font-size: 16px;
  gap: 4px;
  height: 24px;
  line-height: 24px;
  padding: 8px 16px;
  min-width: 150px;

  &:hover,
  &:focus-within,
  .material-icons {
    color: var(--color-search-bar-text-selected);
  }

  ::placeholder {
    color: var(--color-search-bar-placeholder);
  }

  input {
    background-color: inherit;
    color: inherit;
    line-height: inherit;
    font-size: inherit;
    border: 0;
    outline: none;
    flex-grow: 1;
  }

  .material-icons {
    font-size: 24px;
    width: 24px;
  }

  &--nav {
    background-color: var(--color-nav-search-bar-bg);
    color: var(--color-nav-search-bar-text);
    flex-wrap: nowrap;

    &:hover,
    input,
    .material-icons {
      color: var(--color-nav-search-bar-text-selected);
    }

    ::placeholder {
      color: var(--color-nav-search-bar-placeholder);
    }
  }

  &--with-chips {
    background-color: inherit;
    border-radius: var(--radius-100);
    border: 1px solid var(--color-border-default);
    height: inherit;
    flex-wrap: wrap;
    max-height: 124px;
    overflow-y: auto;

    .chip + input::placeholder {
      color: transparent;
    }

    &:focus-within {
      border-color: var(--color-secondary-700);
    }
  }
}


