.card {
  background-color: $white;
  box-shadow: 0 1px 8px 5px #E7E3F1;

  &.card--warning {
    background-color: $light-salmon;
    box-shadow: none;
    border-radius: var(--radius-100);
    color: $navy-blue;

    .material-icons {
      color: $white;
    }
  }
}

// Woots
.woots-card {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

  &.woots-card--azul {
    background-color: $azul;
  }

  &.woots-card--cloudy-blue {
    background-color: $cloudy-blue;
  }

  &.woots-card--grapefruit {
    background-color: $grapefruit;
  }

  &.woots-card--green-blue {
    background-color: $green-blue;
  }

  &.woots-card--maize {
    background-color: $maize;
  }

  &.woots-card--pastel-blue {
    background-color: $pastel-blue;
  }

  img {
    max-width: 35%;
    position: relative;
  }
}
