.horizontal-scroll {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scroll-snap-center {
  scroll-snap-align: center;
}
