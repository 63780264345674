$bg: #FFCC80;

.highlight {
  background-color: $bg;
  cursor: pointer;
}

.color-change-hidden {
  @extend .highlight-poptip;
  display: none !important;
  width: 162px !important;
  cursor: default;
}

@each $color in ("yellow", "red", "green", "purple", "blue") {
  .highlight-color-#{$color} {
    @extend .highlight;
    background-color: var(--color-highlight-color-#{$color});

    &:hover {
      background-color: var(--color-highlight-color-#{$color});
    }
  }
}

.highlight-poptip {
  background-color: white;
  border: 1px solid #ADABAB;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  height: 32px;
  line-height: 15px;
  padding: 0;
  position: absolute;
  text-align: center;

  i {
    font-size: 20px;
    line-height: 20px;
    color: var(--color-icon-button);
  }

  a, .material-icons-filled {
    &:hover {
      background-color: #F2F2F2 !important;
      border-radius: 4px;
    }
  }

  .poptip-button {
    width: 28px;
  }

  .button-active {
    background-color: #E8E8E8 !important;
    border-radius: 4px;
  }
}

.deprecated-highlight-poptip {
  background-color: #ADABAB;
  border: 1px solid #ADABAB;
  border-radius: 50%;
  color: #FFF;
  font-size: 14px;
  height: 20px;
  line-height: 15px;
  padding: 0;
  position: fixed;
  text-align: center;
  width: 20px;

  i {
    font-size: 18px;
    line-height: 20px;
  }

  .description {
    display: block;
    font-size: 100%;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.highlight-comment-summary {
  &:hover {
    background-color: $platinum;
    cursor: pointer;
  }
}
