.sortable-wrapper {
  &.vertical {
    .sortable-list {
      flex-direction: column;
    }

    .placeholder {
      width: calc(100% - 32px);
    }
  }

  .sortable-ghost {
    opacity: 1;

    .sortable-item {
      color: rgba($dove-gray, .7);
    }
  }
}

.sortable-handle {
  .sortable-handle-icon {
    visibility: hidden;
  }
}

.sortable-handle {
  &:hover {
    cursor: grab;

    .sortable-handle-icon {
      visibility: visible;
    }
  }

  &:active {
    cursor: grabbing;
  }
}

.sortable-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 42px;
}

.sorted-list {
  border: 2px solid $navy-blue;
  border-radius: var(--radius-050);
  padding: 10px 4px 4px 10px;
}

.sortable-item {
  align-items: center;
  background-color: var(--color-bg-default);
  border: 1px solid #D1D5DA;
  border-radius: var(--radius-050);
  display: flex;
  margin: 0 6px 6px 0;
  padding: 5px;

  .content {
    width: auto;
  }

  i {
    margin-right: 5px;
  }
}

.high-contrast {
  .sorted-list,
  .sortable-item {
    border-color: $high-contrast-color;
  }

  .sortable-ghost {
    .sortable-item {
      border-color: rgba($high-contrast-color, .4);
      color: rgba($high-contrast-color, .4);
    }
  }
}
