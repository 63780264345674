.hotspot-area-field {
  .mdc-select {
    @include select.min-width(100px);
    @include select.outlined-height(40px);
  }

  .mdc-text-field {
    @include textfield.outlined-height(40px);
    min-width: 100px;
  }
}

.image-mapper-shape {
  // Colors
  &.hotspot-area--amber {
    fill: $mikado-yellow;
    stroke: $mikado-yellow;
    background: rgba($mikado-yellow, .5);
  }

  &.hotspot-area--blue {
    fill: $blue;
    stroke: $blue;
    background: rgba($blue, .5);
  }

  &.hotspot-area--cyan {
    fill: $cyan;
    stroke: $cyan;
    background: rgba($cyan, .5);
  }

  &.hotspot-area--deep-orange {
    fill: $dark-orange;
    stroke: $dark-orange;
    background: rgba($dark-orange, .5);
  }

  &.hotspot-area--deep-purple {
    fill: $navy-blue;
    stroke: $navy-blue;
    background: rgba($navy-blue, .5);
  }

  &.hotspot-area--green {
    fill: $mountain-meadow;
    stroke: $mountain-meadow;
    background: rgba($mountain-meadow, .5);
  }

  &.hotspot-area--indigo {
    fill: $indigo;
    stroke: $indigo;
    background: rgba($indigo, .5);
  }

  &.hotspot-area--light-blue {
    fill: $light-blue;
    stroke: $light-blue;
    background: rgba($light-blue, .5);
  }

  &.hotspot-area--light-green {
    fill: $green;
    stroke: $green;
    background: rgba($green, .5);
  }

  &.hotspot-area--lime {
    fill: $lime;
    stroke: $lime;
    background: rgba($lime, .5);
  }

  &.hotspot-area--orange {
    fill: $orange;
    stroke: $orange;
    background: rgba($orange, .5);
  }

  &.hotspot-area--pink {
    fill: $deep-pink;
    stroke: $deep-pink;
    background: rgba($deep-pink, .5);
  }

  &.hotspot-area--purple {
    fill: $han-purple;
    stroke: $han-purple;
    background: rgba($han-purple, .5);
  }

  &.hotspot-area--red {
    fill: $cardinal;
    stroke: $cardinal;
    background: rgba($cardinal, .5);
  }

  &.hotspot-area--teal {
    fill: $mountain-meadow;
    stroke: $mountain-meadow;
    background: rgba($mountain-meadow, .5);
  }

  &.hotspot-area--yellow {
    fill: $mikado-yellow;
    stroke: $mikado-yellow;
    background: rgba($mikado-yellow, .5);
  }
}

.hotspot-area-label {
  border-radius: var(--radius-050);
  padding: 2px 4px;
}

.woots {
  .hotspot-area-label {
    border-radius: 0;
  }
}
