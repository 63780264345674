.redactor-modal-shortcut {
  margin-top: 10vh !important;
}

.redactor-modal-shortcut .redactor-modal-body {
  max-height: 60vh;
  overflow-y: auto;
  padding: 0
}

.redactor-modal-shortcut .redactor-modal-body .collection {
  margin: 0;
}

.redactor-modal-shortcut .redactor-modal-body .box-row {
  cursor: pointer;
}

.redactor-modal-shortcut .redactor-modal-body .shortcut {
  font-weight: bold;
}

.redactor-modal-shortcut .redactor-modal-body .comment {
  font-size: 11px;
}

.redactor-modal-shortcut .redactor-modal-footer {
  padding: 12px;
}
