.attachment {
  align-items: center;
  border: 1px solid #C9D0D8;
  border-radius: var(--radius-200);
  color: #5F6367;
  display: inline-flex;
  margin-right: 4px;
  padding: 4px 8px;

  &:hover {
    background-color: #F0F2F2;
  }

  .material-icons {
    font-size: 16px;
    margin-right: 4px;
  }
}
