.button {
  align-items: center;
  border-radius: var(--radius-100);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family-header);
  font-size: 16px;
  font-weight: 500;
  gap: 4px;
  height: 40px;
  justify-content: center;
  line-height: 24px;
  padding: 8px 16px;
  white-space: nowrap;
  width: fit-content;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &--small {
    @extend .f5;
    height: 36px;
  }
}

.button-primary,
.button-secondary,
.button-tertiary,
.button-nav,
.split-button__button,
.split-button__toggle  {
  @extend .button;
}

.button-primary,
.button-secondary {
  &:not(:disabled):hover,
  &:not(:disabled):active {
    @extend .elevation-1;
  }
}

.button-primary {
  background-color: var(--color-button-primary-bg);
  border: 1px solid var(--color-button-primary-bg);
  color: var(--color-button-primary-text);

  &:not(:disabled):hover {
    background-color: var(--color-button-primary-bg-hover);
    border: 1px solid var(--color-button-primary-bg-hover);
  }

  &:not(:disabled):active {
    background-color: var(--color-button-primary-bg-active);
    border: 1px solid var(--color-button-primary-bg-active);
  }

  &:disabled {
    background-color: var(--color-button-primary-bg-disabled);
    border: 1px solid var(--color-button-primary-bg-disabled);
    color: var(--color-button-primary-text-disabled);
  }
}

.button-secondary {
  background-color: transparent;
  border: 1px solid var(--color-button-secondary-border);
  color: var(--color-button-secondary-text);

  &:not(:disabled):hover {
    background-color: var(--color-button-secondary-bg-hover);
  }

  &:not(:disabled):active {
     background-color: var(--color-button-secondary-bg-active);
  }
}

.button-tertiary {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-button-tertiary-text);

  &:not(:disabled):hover {
    color: var(--color-button-tertiary-text);
    background-color: var(--color-button-tertiary-bg-hover);
  }

  &:not(:disabled):active {
    color: var(--color-button-tertiary-text);
    background-color: var(--color-button-tertiary-bg-active);
  }

  &--danger {
    color: var(--color-danger-600) !important;

    &:not(:disabled):hover {
      background-color: var(--color-danger-050);
    }

    &:not(:disabled):active {
      background-color: var(--color-danger-100);
    }
  }
}

.button-nav {
  background-color: var(--color-button-nav-bg);
  border: 1px solid var(--color-button-nav-bg);
  color: var(--color-button-nav-text);

  &:not(:disabled):hover {
    background-color: var(--color-button-nav-bg-hover);
    border: 1px solid var(--color-button-nav-bg-hover);
  }

  &:not(:disabled):active {
    background-color: var(--color-button-nav-bg-active);
    border: 1px solid var(--color-button-nav-bg-active);
  }
}

.split-button {
  align-items: flex-start;
  display: flex;

  .split-button__button,
  .split-button__toggle {
    background-color: var(--color-button-primary-bg);
    border: 1px solid var(--color-button-primary-border);
    color: var(--color-button-primary-text);
  }

  .split-button__button {
    border-radius: var(--radius-100) 0 0 var(--radius-100);
    border-right: 0;
    padding: 8px 16px;

    &:not(:disabled):hover {
      background-color: var(--color-button-primary-bg-hover);
    }

    &:not(:disabled):active {
      background-color: var(--color-button-primary-bg-active);
    }

    &:disabled {
      background-color: var(--color-button-primary-bg-disabled);
      color: var(--color-button-primary-text-disabled);
    }
  }

  .split-button__toggle {
    border-radius: 0 var(--radius-100) var(--radius-100) 0;
    min-width: 20px;
    padding: 8px;
    margin-left: 2px;
    vertical-align: bottom;

    &.material-icons {
      color: var(--color-button-primary-text);
      font-size: 24px !important;
    }

    &:not(:disabled):hover {
      background-color: var(--color-button-primary-bg-hover);
    }
  }
}

.button-icon {
  @extend .button;
  @extend .material-icons;

  color: var(--color-icon-default);
  font-size: 24px;
  height: 40px;
  width:  40px;

  &:hover {
    background-color: var(--color-bg-150);
  }

  &:active {
    background-color: var(--color-bg-200);
  }

  &--small {
    border-radius: var(--radius-050);
    font-size: 20px;
    height: 28px;
    padding: 4px;
    width:  28px;
  }

  &--toggled {
    color: var(--color-button-icon-toggled) !important;
  }

  &--nav {
    color: var(--color-text-050);

    &:hover {
      background-color: var(--color-secondary-700);
    }

    &:active {
      background-color: var(--color-secondary-800);
    }
  }
}
