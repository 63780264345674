.indicator,
.indicator--secondary,
.indicator--list-item {
  &:before,
  &:after {
    border-radius: 50%;
    height: 8px;
    opacity: 1 !important;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 8px;
  }
}

.indicator,
.indicator--list-item {
  &:before {
    background: var(--color-primary-500);
    content: '';
  }
}

.indicator--list-item {
  &:before {
    right: -5px;
    top: -4px;
  }
}

.indicator--secondary {
  &:after {
    background: $light-blue !important;
    content: '';
  }

  &.indicator--left {
    &:after {
      left: 3px;
    }
  }
}

.menu-indicator {
  &:has(input:checked) + .mdc-menu-trigger {
    @extend .indicator;

    &:before {
      background: var(--color-secondary-400) !important;
    }
  }
}

.indicator-dot {
  background-color: var(--color-primary-500);
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 12px
}
