.pin-code {
  height: 44px;
  margin-right: 8px;
  max-width: 100%;
  text-align: center;
  width: 44px;

  @media (max-width: 599px) {
    height: 30px;
    width: 30px;
  }
}
