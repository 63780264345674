@keyframes appear-then-fade {
  0%, 100% {
    opacity:0
  }
  5%, 60% {
    opacity:1
  }
}

.appear-then-fade {
  animation: appear-then-fade 4s both;
}

@keyframes appear-and-stay {
  0% {
    opacity:0;
  }
  5% {
    opacity:1;
  }
  100% {
    opacity:1;
  }
}

.appear-and-stay {
  animation: appear-and-stay 4s forwards;
}

@keyframes light-up {
  0% { background-color: transparent; }
  10%, 75% { background-color: var(--color-light-up-bg); }
  100% { background-color: transparent; }
}

.light-up {
  animation: light-up 2.4s;
}

@keyframes scale-appear {
  from { transform: scale(0); }
  to { transform: scale(1); }
}
