.mdc-top-app-bar--brand {
  @include top-app-bar.fill-color($han-purple);
  @include top-app-bar.ink-color($white);
}

.mdc-top-app-bar--brand {
  .indicator {
    &:before {
      background: var(--color-primary-300);
    }
  }
}

.mdc-top-app-bar--landing-page {
  @include top-app-bar.fill-color($white);
  @include top-app-bar.ink-color($navy-blue);
}

.mdc-top-app-bar__section {
  z-index: 7;
}

.woots {
  .mdc-top-app-bar--brand {
    @include top-app-bar.fill-color($azul);

    .indicator {
      &:before {
        background: var(--color-primary-200);
      }
    }
  }
}

.high-contrast {
  .mdc-top-app-bar {
    background-color: $high-contrast-color !important;
    color: $high-contrast-background-color !important;

    .mdc-icon-button {
      @include icon-button.ink-color($high-contrast-background-color);

      color: $high-contrast-background-color;
    }
  }
}
