.legend-square {
  height: 16px;
  width: 16px;
}

.legend-square-capability-3 {
  @extend .legend-square;
  background-color: #D2F9E4;
}

.legend-square-capability-2 {
  @extend .legend-square;
  background-color: #A8F2CE;
}

.legend-square-capability-1 {
  @extend .legend-square;
  background-color: #70E5B5;
}

.legend-square-incapability-3 {
  @extend .legend-square;
  background-color: #FEE2E4;
}

.legend-square-incapability-2 {
  @extend .legend-square;
  background-color: #FECACE;
}

.legend-square-incapability-1 {
  @extend .legend-square;
  background-color: #FCA5AC;
}
