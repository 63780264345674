body {
  --keyboard-zindex: 3000;

  math-field {
    border: 1px solid var(--color-border-default);
    border-radius: 5px;
    display: block;
    font-size: 16px;
    padding: 5px;
  }

  math-field::part(menu-toggle) {
    display: none;
  }

  #mathlive-suggestion-popover {
    z-index: 3001 !important;
  }
}
