.question-buttons {
  background-color: transparent;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  gap: 8px;
  overflow-x: auto;
  position: relative;
}

.question-button {
  align-items: center;
  background: var(--color-question-button-default-bg);;
  border-bottom: 2px solid var(--color-question-button-default-border);
  border-left: 1px solid var(--color-question-button-default-border);
  border-radius: var(--radius-100, 8px);
  border-right: 1px solid var(--color-question-button-default-border);
  border-top: 1px solid var(--color-question-button-default-border);
  box-sizing: border-box;
  color: var(--color-question-button-default-text);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-header);
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  line-height: 24px;
  min-width: 40px;
  max-width: 40px;
  padding: 8px;
  position: relative;

  &.active,
  &.question-button--current {
    background-color: var(--color-question-button-default-bg-selected);
  }

  &:not(.active):hover {
    background-color: var(--color-question-button-default-bg-hover);
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
  }

  &.question-button--answered {
    border-color: var(--color-question-button-info-border);

    &.active,
    &.question-button--current {
      background-color: var(--color-question-button-info-bg-selected);
    }

    &:not(.active):hover {
      background-color: var(--color-question-button-info-bg-hover);
    }
  }

  &.question-button--correct {
    border-color: var(--color-question-button-success-border);

    &.active,
    &.question-button--current {
      background-color: var(--color-question-button-success-bg-selected);
    }

    &:not(.active):hover {
      background-color: var(--color-question-button-success-bg-hover);
    }
  }

  &.question-button--mistake {
    border-color: var(--color-question-button-warning-border);

    &.active,
    &.question-button--current {
      background-color: var(--color-question-button-warning-bg-selected);
    }

    &:not(.active):hover {
      background-color: var(--color-question-button-warning-bg-hover);
    }
  }

  &.question-button--incorrect {
    border-color: var(--color-question-button-danger-border);

    &.active,
    &.question-button--current {
      background-color: var(--color-question-button-danger-bg-selected);
    }

    &:not(.active):hover {
      background-color: var(--color-question-button-danger-bg-hover);
    }
  }

  &.question-button--locked {
    -webkit-font-smoothing: unset;
    pointer-events: none;
    visibility: hidden;

    &:before {
      background-color: #E1E4E8;
      border-radius: var(--radius-100);
      box-sizing: border-box;
      content: "";
      height: 40px;
      min-width: 40px;
      position: absolute;
      top: 0;
      visibility: visible;
    }

    &:after {
      -moz-font-feature-settings: 'liga' 1;
      -webkit-font-feature-settings: 'liga' 1;
      content: 'lock';
      display: block;
      font-family: 'Material Icons Outlined';
      font-feature-settings: 'liga' 1;
      font-size: 24px;
      padding: 5px 0;
      position: absolute;
      top: 2px;
      visibility: visible;
    }
  }
}

.arrow-button:hover {
  background-color: var(--color-secondary-050, #EBEEFF);
}

.question-button-indicator {
  background-color: var(--color-question-button-indicator-bg);
  border-radius: 0 8px 8px 0;
  height: 32px;
  left: -12px;
  position: absolute;
  top: calc(50% - 16px);
  width: 4px;
}

.question-button-icon {
  align-items: center;
  background-color: var(--color-question-button-icon-bg);
  border-radius: 100%;
  color: var(--color-question-button-icon-text);
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 16px;
}
