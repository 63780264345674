.glider-slide {
  min-width: 100px;
}

.glider-image {
  border: 1px solid #AAA;
  box-sizing: border-box;
  height: auto;
  min-height: 100px;
  min-width: 100px;
  width: 100px;
}

.glider-left,
.glider-right {
  top: calc(50% - 12px);
}
