.quiz-panel {
  background-color: var(--color-bg-default);
}

.compiled-response {
  border: 1px solid rgba(0, 0, 0, .075);
  border-radius: var(--radius-100);
  min-height: 145px;
  padding: 16px 18px;
  position: relative;

  .hover-effect {
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 3px;
    color: #FFF;
    display: flex;
    font-size: 2em;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
  }

  &:hover {
    .hover-effect {
      visibility: visible;
    }
  }
}

.file-preview {
  img, video {
    max-height: 250px;
  }
}

.active-submission {
  border: 1px solid $han-purple;
  cursor: auto;
}

.quiz-subquestion {
  &[data-locked='true'] {
    pointer-events: none;

    .quiz-submission-form {
      border: 2px solid #CB212F;
      border-radius: var(--radius-050);
    }
  }
}

.save-timestamp {
  min-height: 18px;
}

.enlarge-img {
  min-width: 60vw;
}

.high-contrast {
  .quiz-panel,
  .save-timestamp {
    color: $high-contrast-color !important;
  }

  .compiled-response {
    border-color: $high-contrast-color;
  }
}

.rsbtn {
  .rsbtn_powered {
    display: none;
  }
}

.preview-placeholder {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.question-title-placeholder {
  font-size: 1.25em;
  font-weight: bold;
}
