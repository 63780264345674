.quiz-navigation {
  background-color: var(--color-canvas-subtle);
  padding: 12px 32px;
}

.high-contrast {
  .quiz-navigation {
    background-color: $high-contrast-background-color;
    border-color: $high-contrast-color;
  }
}
